const env = process.env.VUE_APP_MOBILE_ENV

const hostConf = {
  qa: '//testc.open.163.com/',
  production: '//c.open.163.com/',
  development: '/'
}[env]

const getCopen = getCurryUrl(hostConf, 'get')
const postCopen = getCurryUrl(hostConf, 'post')

export default {
  /**
   * 想法详情
   * @param  {String}  options.contentId   内容id
   * @param  {String}  options.type   20-问号君评论；10-课间投票评论；8-课间单图评论；9-课间多图评论；2-视频；6-音频；3-专题；4-文章；5-图集；120-一万分钟；210-个人动态 211想法
   */
  commentNum: getCopen('open/mob/comment/num.do'),
  /**
   * 微信是否登录
   */
  checkLogin: getCopen('open/mob/weixin/getWeixinloginStatus.do', ''),
  /**
   * 课单回流页详情
   */
  courseListInfo: getCopen('open/mob/courseList/content/info.do', 'open'),
  /**
   * 回流页获取课程 by plid
   */
  getMovieList: getCopen(`open/mob/movie/list.do`, ''),
  /**
   * 回流页过去电台数据
   * @params {string} plid
   * @params {string} mid
   * ps: old api -> open/mob/audio/${plid}/getAudios.do 参数在链接中
   */
  getAudioListForBackGuide: getCopen(`open/mob/audio/getAudioInfoAround.do`, ''),
  /**
   * fm频道
   */
  getFmChannelsForBackGuide: getCopen(`open/mob/audio/fm/listTabInfo.do`, ''),
  /**
   * fm频道音频
   */
  getFmAudiosForBackGuide: getCopen(`open/mob/audio/fm/listContentInfo.do`, ''),
  /**
   * 回流页获取推荐课程 by plid
   */
  getMovieRecommendList: getCopen(`open/mob/movie/recommend/list.do`, ''),
  /**
   * 回流页获取推荐课程 by plid
   */
  getTopCourseList: getCopen(`open/mob/h5/plan/topCourseList.do`, ''),
  /**
   * 提问详情
   * @params {string} id
   */
  getQuestionDetail: getCopen(`open/mob/classbreak/h5AskDetail.do`),
  /**
   * 想法详情
   * @params {number} id
   */
  getIdeaDetail: getCopen(`open/mob/classbreak/ideaDetail.do`),
  /**
   * 社区小组下话题列表
   * @params {number} groupId 小组id
   * @params {number} scene  场景id  1_想法发布页   2_小组首页  3_小组详情页
   * @params {number} cursor 游标 result会带上下次请求的游标
   */
  getTopicList: getCopen(`open/mob/community/topic/getTopicList.do`),
  /**
   * 社区话题详情
   * @params {number} topicId
   */
  getTopicDetail: getCopen(`open/mob/community/topic/getTopicInfo.do`),
  /**
   * 社区话题feed
   * @params {number} topicId
   * @params {string} order score_最热,time_最新
   * @params {number} cursor
   */
  getTopicFeed: getCopen(`open/mob/community/topic/listContent.do`),
  /**
   * 社区小组详情
   * @params {number} groupId
   */
  getCircleDetail: getCopen(`open/mob/square/community/getGroupBasicInfo.do`),
  /**
   * 社区小组feed
   * @params {number} groupId
   * @params {number} pagesize
   */
  getCircleFeed: getCopen(`open/mob/square/community/getGroupFeed.do`),
  /**
   * 回流页底部-公共社区内容
   * @params {number} groupId
   * @params {number} pagesize
   */
  getCommonCircleFeed: getCopen(`open/mob/community/rec/getRecList.do`),
  /**
   * 社区小组置顶公告
   * @params {number} groupId
   */
  getCircleNotices: getCopen(`open/mob/square/community/listOnBoardInfo.do`),
  /**
   * 社区动态详情
   * @params {number} classBreakId
   */
  getCircleFeedDetail: getCopen(`open/mob/square/community/getContentInfo.do`),
  /**
   * 评论列表
   * @params {number} type 211-想法，20-提问，2-单视频，6-音频，4-文章
   * @params {number} sort 1, 1-最新，0-热门
   * @params {number} pagesize
   * @params {string} contentId
   * @params {string} cursor
   * example https://c.open.163.com/open/mob/comment/h5/list.do?contentId=173881&cursor=&pagesize=3&sort=1&type=211
   */
  getCommentListNew: getCopen(`open/mob/comment/h5/list.do`, 'open'),
  /**
   * 想法评论数
   * @params {number} type
   * @params {number} contentId
   */
  getCommentNum: getCopen(`open/mob/comment/num.do`),
  /**
   * 评论点赞
   * @params {number} contentId
   * @params {parentId} 一级为0
   */
  commentLike: postCopen(`open/mob/comment/v2/commentLike.do`, 'open'),
  /**
   * 订阅号详情
   * @params {string} subscribeId
   */
  getSubscriptionDetail: getCopen(`open/mob/subscribe/detail/info.do`),
  /**
   * 订阅号内容列表
   * @params {string} subscribeId
   * @params {string} rtypes=2,3,4,5,6,8,9,10,11,12
   * @params {string} cursor
   * @params {number} pagesize
   */
  getSubscriptionList: getCopen(`open/mob/subscribe/detail/list.do`),
  /**
   * 订阅号文章详情
   * @params {string} id
   */
  getSubscriptionArticle: getCopen(`open/mob/subscribe/imageArticle/detail.do`),
  /**
   * 投票详情
   * @params {number} id
   */
  getVoteDetail: getCopen(`mob/classBreak/voteDetail.do`),
  /**
   * 投票提交
   * @params {number} id
   * @params {string} voteJson [{"itemId":1648}]
   */
  voteSubmit: postCopen(`mob/classBreak/vote.do`),
  /**
   * 图文详情
   * @params {number} id
   */
  getRichTextArticle: getCopen(`open/mob/classbreak/preview.do`),
  /**
   * 免费首页Tab
   */
  getFreeHomeTabs: getCopen(`open/mob/classify/applet/classifyList.do`, ''),
  /**
   * 首页入口配置
   */
  getHomeEntries: getCopen(`open/mob/h5/homePage/gk/list.do`, ''),
  /**
   * 免费首页列表（不包括推荐）, 获取 最新/最热 列表，new 1/hot 2
   * pagesize
   * flag 1/2
   * cursor
   * classifyId
   */
  getFreeHomeList: getCopen(`open/mob/classify/applet/classifyPlayList.do`, ''),
  /**
   * 免费分类列表
   */
  getFreeClassifyList: getCopen(`open/mob/classify/applet/list.do`, ''),
  /**
   * 免费首页推荐
   * netType: 1 - 3g, 2 - wifi
   * fnType: reload 0, other 1
   * fn: 0, 0 or nextFn,
   * passport: deviceid
   */
  getFreeHomeRecommend: postCopen(`open/mob/baidu/feed/list.do`, ''),
  /**
   * 搜索
   * keyword: string
   * cursor
   * searchId
   */
  getSearchContents: getCopen(`open/mob/searchv2/v2/queryAll.do`, ''),
  /**
   * 搜索框及hotwords
   */
  getSearchHotwords: getCopen(`open/mob/searchv2/pcsughotword.do`, ''),
  /**
   * 学习计划
   * planKey: String
   */
  getPlanDetail: getCopen(`open/mob/target/plan/detail.do`, ''),
  /**
   * 社群学习排行榜
   * rankKey: 社群ID
   * rankType: 0 本周学习榜单，1 连续学习榜单 2 累计学习榜单
   */
  getCommunityStudytimeRankData: getCopen(`open/mob/target/rankList.do`, ''),
  /**
   * 社群加群 - 详情
   * id: 社群ID
   */
  getCommunityWxmp: getCopen(`open/mob/community/rec/getOutGroup.do`, ''),
  /**
   * 社群加群 - 小程序scheme
   * id: 社群ID
   * 
   */
  getCommunityWxmpScheme: postCopen(`open/mob/livestream/applet/getScheme.do`, ''),
  /**
   * 社群学习排行榜-我的排名
   * rankKey: 社群ID
   * rankType: 0 本周学习榜单，1 连续学习榜单 2 累计学习榜单
   */
   getCommunityStudytimeMyRank: getCopen(`open/mob/target/myRank.do`, ''),
  /**
   * h5登录信息
   */
  getLoginInfo: getCopen(`open/mob/user/info.do`, ''),
  /**
   * 推荐卡片排行榜
   * moduleId=10121
   * moduleType=3
   * pagesize=50
   * offset=0
   */
  getRecommendCardRank: getCopen(`open/mob/cardModule/rankModule/list.do`, 'open'),
  /**
   * Adobe 认证获取验证码
   * email
   */
  adobeAuthenticateGetCode: postCopen(`open/mob/adobe/auth/sendEmailCode.do`, 'open'),
  /**
   * Adobe 认证获提交
   * email
   * code
   */
  adobeAuthenticateSubmit: postCopen(`open/mob/adobe/auth/verifyCode.do`, 'open'),
  /**
   * 学校信息
   */
  getSchoolSubjectInfo: getCopen(`open/mob/findcourse/school/detail.do`, 'open'),
  /**
   * 名师信息
   */
  getTeacherSubjectInfo: getCopen(`open/mob/findcourse/director/detail.do`, 'open'),
  /**
   * 学校课程列表
   * thirdCid, sortType, id, subjectName, cursor
   */
  getSchoolSubjectCtns: getCopen(`open/mob/findcourse/school/contentsv2.do`, 'open'),
  /**
   * 名师课程列表
   */
  getTeacherSubjectCtns: getCopen(`open/mob/findcourse/director/contentsv2.do`, 'open'),
  /**
   * 用户勋章详情
   * encryptUserMedalId
   */
  getUserMedalDetail: getCopen(`open/mob/medal/detail.do`, 'open'),
  /**
   * 勋章列表
   * userId
   */
  getUserMedals: getCopen(`open/mob/medal/listv3.do`, 'open'),
  /**
   * 佩戴勋章
   * userMedalId
   */
  wearUserMedal: postCopen(`open/mob/medal/wear.do`, 'open'),
  /**
   * 摘掉勋章
   * userMedalId
   */
  unwearUserMedal: postCopen(`open/mob/medal/unwear.do`, 'open'),
  /**
   * 学习笔记和合集信息及邻近笔记
   * mid,noteId
   */
  getStudynoteNearby: getCopen(`open/mob/studynote/nearby.do`, 'open'),
  /**
   * 学习笔记和合集信息前后笔记
   * mid,noteId
   */
  getStudynotePart: getCopen(`open/mob/studynote/part.do`, 'open'),
  /**
   * 学习笔记删除
   * noteItemId
   */
  deleteStudynote: postCopen(`open/mob/studynote/item/delete.do`, 'open'),
  /**
   * 提交播放记录信息
   */
  saveCadreRecord: postCopen(`open/mob/cadre/saveRecord.do`, ''),
  saveCadreRecordTest: postCopen(`https://testc.open.163.com/open/mob/cadre/saveRecord.do`, ''),
  /** 
   * 裂变活动 - 活动详情
   * activityId
   */
  getActivityDetail: getCopen(`open/mob/activity/common/info.do`, 'open'),
  /**
   * 裂变活动 - 活动用户基本信息
   * activityId
   */
  getActivityUserInfo: getCopen(`open/mob/activity/common/user/info.do`, 'open'),
  /**
   * 裂变活动 - 活动排行榜
   * activityId
   */
  getActivityRankList: getCopen(`open/mob/activity/common/rank/list.do`, 'open'),
  /**
   * 裂变活动 - 活动邀请记录
   * activityId
   */
  getActivityInviteLog: getCopen(`open/mob/activity/common/user/inviteLog.do`, 'open'),
  /**
   * 裂变活动 - 活动奖品兑换列表
   * activityId
   */
  getActivityConsumeLog: getCopen(`open/mob/activity/common/user/consumeLog.do`, 'open'),
  /**
   * 裂变活动 - 活动助力用户
   * assistKey
   */
  postActivityAssist: postCopen(`open/mob/activity/common/user/assist.do`, 'open'),
  /**
   * 裂变活动 - 活动领取奖励
   * rewardId
   */
  postActivityReward: postCopen(`open/mob/activity/common/user/drawReward.do`, 'open'),
  /**
   * 2022年终总结 - 用户数据
   */
  getEnding2022ActivityInfo: getCopen(`open/mob/annual/report/info.do`, 'open'),
  /**
   * 2022年终总结 - 用户数据
   */
  saveFlagsFromEnding2022Activity: postCopen(`open/mob/annual/report/flag/save.do`, 'open'),
}
/**
 * 生成请求对象
 */
function getCurryUrl (url, reqType) {
  return (urlName, moreInfo) => {
    return {
      urlName,
      url,
      type: reqType,
      moreInfo
    }
  }
}
