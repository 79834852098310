import toast from '@/utils/toast'

const OPEN_CLICK_TRACKER = 'http://click.portal.163.com/open/'

/**
 * 章鱼clickp统计
 * @param eventName {String} 统计锚点名
 * 如果有参数 ，把参数拼接到eventName后采用eventName?key1=value1&key2=value2的方法传入
 * @param name {String} 统计显示名称
 */
function clickTracker (eventName, name = null, param = {}) {
  let _query = '?'
  let strs = []
  Object.keys(param).forEach((key) => {
    strs.push(`${key}=${param[key]}`)
  })
  _query += strs.join('&')
  if (_query.length > 1) {
    eventName = eventName + _query
  }
  let neteaseTracker = window.neteaseTracker
  if (typeof neteaseTracker === 'function') {
    neteaseTracker(null, OPEN_CLICK_TRACKER + eventName, name || eventName, 'clickp')
  }
}

function reloadPage (reloadBefore = () => {}) {
  reloadBefore()
  window.location.reload()
}

const publicMethods = {
  install (Vue) {
    Object.assign(Vue.config.globalProperties, {
      $setTitle: title => document.title = `${title}_网易公开课`,
      $setTitleJust: (title) => { document.title = title || `网易公开课` },
      $clickTracker: clickTracker,
      $toast: toast,
      $reloadPage: reloadPage,
    })
    window.$toast = toast
  }
}

export default publicMethods
