import { createRouter, createWebHistory } from 'vue-router'

function loadCatch (e) {
  window.$toast && window.$toast.show('抱歉，网络出错')
  console.log(e)
}

const routes = [
  {
    path: '/applinks/:pname?',
    name: 'AppLinks',
    component: () => import(/* webpackChunkName: "GuideBackAppLinks" */ '@/components/AppLinks').catch(loadCatch),
  },
  {
    path: '/mobile/free/gb/home',
    name: 'GuideBackHome',
    component: () => import(/* webpackChunkName: "GuideBackHome" */ '@/components/Free/GuideBack/Home').catch(loadCatch),
    meta: { keepAlive: true }
  },
  {
    path: '/mobile/free/gb/classify',
    name: 'GuideBackClassify',
    component: () => import(/* webpackChunkName: "GuideBackClassify" */ '@/components/Free/GuideBack/Classify').catch(loadCatch),
    meta: { keepAlive: true }
  },
  {
    path: '/mobile/free/gb/search/:kw?',
    name: 'GuideBackSearch',
    component: () => import(/* webpackChunkName: "GuideBackSearch" */ '@/components/Free/GuideBack/Search').catch(loadCatch),
    meta: { keepAlive: true }
  },
  {
    path: '/mobile/free/gb/offlinetest',
    name: 'GuideBackOfflineTest',
    component: () => import(/* webpackChunkName: "GuideBackOfflineTest" */ '@/components/Free/GuideBack/OfflineTest').catch(loadCatch),
  },
  {
    path: '/mobile/free/gb/freelist',
    name: 'GuideBackFreeList',
    component: () => import(/* webpackChunkName: "GuideBackFreeList" */ '@/components/Free/GuideBack/FreeList').catch(loadCatch),
    meta: { keepAlive: true }
  },
  {
    path: '/mobile/free/gb/activity/noterecommend',
    name: 'GuideBackActivityNoteRecommend',
    component: () => import(/* webpackChunkName: "GuideBackActivityNoteRecommend" */ '@/components/Free/GuideBack/Activities/Note/noteRecommend').catch(loadCatch),
  },
  {
    path: '/mobile/free/gb/activity/assistancetask',
    name: 'GuideBackActivityAssistanceTask',
    component: () => import(/* webpackChunkName: "GuideBackActivityAssistanceTask" */ '@/components/Free/GuideBack/Activities/AssistanceTask').catch(loadCatch),
  },
  {
    path: '/mobile/free/gb/video',
    name: 'GuideBackVideo',
    component: () => import(/* webpackChunkName: "GuideBackVideo" */ '@/components/Free/GuideBack/Video').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/videomixedcommunity',
    name: 'GuideBackVideoMixedCommunity',
    component: () => import(/* webpackChunkName: "GuideBackVideoMixedCommunity" */ '@/components/Free/GuideBack/VideoMixedCommunity').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/audioplay',
    name: 'GuideBackAudioPlay',
    component: () => import(/* webpackChunkName: "GuideBackAudioPlay" */ '@/components/Free/GuideBack/AudioPlay').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/courselist',
    name: 'CourseListGuideBack',
    component: () => import(/* webpackChunkName: "GuideBackCourseList" */ '@/components/Free/GuideBack/CourseList').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/topic',
    name: 'GuideBackTopic',
    component: () => import(/* webpackChunkName: "GuideBackTopic" */ '@/components/Free/GuideBack/Topic').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/circle',
    name: 'GuideBackCircle',
    component: () => import(/* webpackChunkName: "GuideBackCircle" */ '@/components/Free/GuideBack/Circle').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/circlefeed',
    name: 'GuideBackCircleFeed',
    component: () => import(/* webpackChunkName: "GuideBackCircleFeed" */ '@/components/Free/GuideBack/CircleFeed').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/idea/:id',
    name: 'GuideBackIdea',
    component: () => import(/* webpackChunkName: "GuideBackIdea" */ '@/components/Free/GuideBack/Idea').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/emp',
    name: 'GuideBackEmpty',
    component: () => import(/* webpackChunkName: "GuideBackEmpty" */ '@/components/Free/GuideBack/Empty').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/fm',
    name: 'GuideBackFm',
    component: () => import(/* webpackChunkName: "GuideBackFm" */ '@/components/Free/GuideBack/Fm').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/subscription',
    name: 'GuideBackSubscription',
    component: () => import(/* webpackChunkName: "GuideBackSubscription" */ '@/components/Free/GuideBack/Subscription').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/subscriptionarticle',
    name: 'GuideBackSubscriptionArticle',
    component: () => import(/* webpackChunkName: "GuideBackSubscriptionArticle" */ '@/components/Free/GuideBack/SubscriptionArticle').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/question',
    name: 'GuideBackQuestion',
    component: () => import(/* webpackChunkName: "GuideBackQuestion" */ '@/components/Free/GuideBack/Question').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/vote',
    name: 'GuideBackVote',
    component: () => import(/* webpackChunkName: "GuideBackVote" */ '@/components/Free/GuideBack/Vote').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/richtextarticle',
    name: 'GuideBackRichTextArticle',
    component: () => import(/* webpackChunkName: "GuideBackRichTextArticle" */ '@/components/Free/GuideBack/RichTextArticle').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/studyplan',
    name: 'GuideBackStudyPlan',
    component: () => import(/* webpackChunkName: "GuideBackStudyPlan" */ '@/components/Free/GuideBack/StudyPlan').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/appservicewxmp',
    name: 'GuideBackAppServiceWxmp',
    component: () => import(/* webpackChunkName: "GuideBackAppServiceWxmp" */ '@/components/Free/GuideBack/AppServiceWxmp').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/communitywxmp',
    name: 'GuideBackCommunityWxmp',
    component: () => import(/* webpackChunkName: "GuideBackCommunityWxmp" */ '@/components/Free/GuideBack/CommunityWxmp').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/communitywzwxmp',
    name: 'GuideBackCommunityWZWxmp',
    component: () => import(/* webpackChunkName: "GuideBackCommunityWZWxmp" */ '@/components/Free/GuideBack/CommunityWZWxmp').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/communityactivity',
    name: 'GuideBackCommunityActivity',
    component: () => import(/* webpackChunkName: "GuideBackCommunityActivity" */ '@/components/Free/GuideBack/CommunityActivity').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/communitystudytimerank',
    name: 'GuideBackCommunityStudytimeRank',
    component: () => import(/* webpackChunkName: "GuideBackCommunityStudytimeRank" */ '@/components/Free/GuideBack/CommunityStudytimeRank').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/recommendcardrank',
    name: 'GuideBackRecommendCardRank',
    component: () => import(/* webpackChunkName: "GuideBackRecommendCardRank" */ '@/components/Free/GuideBack/RecommendCardRank').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/subject',
    name: 'GuideBackSubject',
    component: () => import(/* webpackChunkName: "GuideBackSubject" */ '@/components/Free/GuideBack/Subject').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/medals',
    name: 'GuideBackMedals',
    component: () => import(/* webpackChunkName: "GuideBackMedals" */ '@/components/Free/GuideBack/Medals').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/medaldetail',
    name: 'GuideBackMedalDetail',
    component: () => import(/* webpackChunkName: "GuideBackMedalDetail" */ '@/components/Free/GuideBack/MedalDetail').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/noteDetail',
    name: 'NoteDetail',
    component: () => import(/* webpackChunkName: "NoteDetail" */ '@/components/Free/GuideBack/NoteDetail').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/bottom/noteDetail',
    name: 'NoteDetailBottom',
    component: () => import(/* webpackChunkName: "NoteDetailBottom" */ '@/components/Free/GuideBack/NoteDetailBottom').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/share/noteDetail',
    name: 'NoteDetailShare',
    component: () => import(/* webpackChunkName: "NoteDetailShare" */ '@/components/Free/GuideBack/NoteDetailShare').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/preview/noteDetail',
    name: 'NoteDetailPreview',
    component: () => import(/* webpackChunkName: "NoteDetailPreview" */ '@/components/Free/GuideBack/NoteDetailPreview').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/cadre/courseplay',
    name: 'CadreCoursePlay',
    component: () => import(/* webpackChunkName: "CadreCoursePlay" */ '@/components/cadre/courseplay').catch(loadCatch)
  },
  {
    path: '/mobile/free/gb/activity/assistancetask',
    name: 'GuideBackActivityAssistanceTask',
    component: () => import(/* webpackChunkName: "GuideBackActivityAssistanceTask" */ '@/components/Free/GuideBack/Activities/AssistanceTask').catch(loadCatch),
  },
  {
    path: '/mobile/free/gb/activity/2022ending',
    name: 'GuideBackActivityEnding2022',
    component: () => import(/* webpackChunkName: "GuideBackActivityEnding2022" */ '@/components/Free/GuideBack/Activities/Ending2022').catch(loadCatch),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
