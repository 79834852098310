import { isNewsSdk, isOpenApp } from '@/utils/browser'

const APP_OPEN_URL = 'https://openapi.ws.netease.com/applinks/'

export const addZero = (a) => `${a < 10 ? '0' : ''}${a}`

export const inApp = () => isNewsSdk() || isOpenApp()

export const getFrom = () => {
  const execres = /[?&]from=((?![#?&]).)+/ig.exec(location.href)
  const fromval = Array.isArray(execres) && typeof execres[0] === 'string' ? execres[0].replace(/^[?&]from=/i, '') : ''
  return fromval
}

export const openApp = (opt, pfrom) => {
  const from = pfrom || getFrom()
  const inAPP = inApp()
  if (opt) {
    if (inAPP) window.OpenCourseJSBridge.invoke('pageJumpEvent', { name: 'payCourseDetail', data: { ...opt, target: 0, channel: from } })
    else location.href = `${APP_OPEN_URL}payCourseDetail?id=${opt?.courseId}&callType=paidContent&url=${encodeURIComponent(location.href)}&from=${from}`
  } else {
    if (inAPP) window.OpenCourseJSBridge.invoke('pageJumpEvent', { name: 'homePage' })
    else location.href = `${APP_OPEN_URL}homePage?callType=paidContent&url=${encodeURIComponent(location.href)}&from=${from}`
  }
}
// 页面跳转
export const eventPage = (url, pfrom) => {
  const from = pfrom || getFrom()
  if (!url) return
  if (inApp()) {
    location.href = url
  } else if (from) {
    location.href = `${APP_OPEN_URL}eventDetail?callType=paidContent&url=${encodeURIComponent(url)}&from=${from}`
  } else {
    location.href = `${APP_OPEN_URL}eventDetail?callType=paidContent&url=${encodeURIComponent(url)}`
  }
}
/**
 *
 * @param {string} plid 免费视频合集ID
 * @param {string} mid 免费视频单集ID
 */
export const goVideoPage = (plid, mid, pfrom) => {
  const from = pfrom || getFrom()
  const inAPP = inApp()
  if (inAPP) window.OpenCourseJSBridge.invoke('pageJumpEvent', { name: 'videoDetail', data: { plid, mid, channel: from } })
  else location.href = `${APP_OPEN_URL}videoDetail?callType=paidContent&plid=${plid}&mid=${mid}&from=${from}`
}
/**
 *
 * @param {string} plid 免费视频合集ID
 * @param {string} mid 免费视频单集ID
 * @param {string} pfrom 来源
 * @param {string} noteId 笔记ID
 * @param {string} point 知识点
 * @param {string} seektime 播放时间点
 */
 export const goNoteDetailPage = ({
  plid,
  mid,
  pfrom,
  noteId,
  point,
  seektime
 }) => {
  const from = pfrom || getFrom()
  const inAPP = inApp()
  if (inAPP) window.OpenCourseJSBridge.invoke('pageJumpEvent', { name: 'noteDetail', data: { plid, mid, channel: from, noteId, point, seektime } })
  else location.href = `${APP_OPEN_URL}noteDetail?callType=paidContent&plid=${plid}&mid=${mid}&from=${from}&noteId=${noteId}&point=${point || ''}&seektime=${seektime || ''}`
}
/**
 *
 * @param {string} plid 免费音频合集ID
 * @param {string} mid 免费音频单集ID
 */
export const goAudioPage = (plid, mid, pfrom) => {
  const from = pfrom || getFrom()
  const inAPP = inApp()
  mid = mid || undefined
  if (inAPP) window.OpenCourseJSBridge.invoke('pageJumpEvent', { name: 'audioDetail', data: { plid, mid, from } })
  else location.href = `${APP_OPEN_URL}audioDetail?callType=paidContent&plid=${plid}&mid=${mid}&from=${from}`
}
/**
 *
 * @param {number} moduleId 频道ID，必传，可传不存在的值
 * @param {string} plid 免费音频合集ID，必传，可传不存在的值
 * @param {string} mid 免费音频单集ID，必传，可传不存在的值
 */
export const goFmPage = (moduleId = 1000, plid = 'a', mid = 'b') => {
  const from = getFrom()
  const inAPP = inApp()
  if (inAPP) window.OpenCourseJSBridge.invoke('pageJumpEvent', { name: 'fmDetail', data: { moduleId, plid, mid } })
  else location.href = `${APP_OPEN_URL}fmDetail?callType=paidContent&moduleId=${moduleId}&plid=${plid}&mid=${mid}&from=${from}`
}
/**
 *
 * @param {number} id 问题id
 */
export const goAskPage = (id) => {
  const from = getFrom()
  location.href = `${APP_OPEN_URL}askDetail?callType=paidContent&id=${id}&from=${from}`
}
/**
 *
 * @param {number} id 马克图文id
 */
export const goMarkPage = (id) => {
  const from = getFrom()
  location.href = `${APP_OPEN_URL}markDetail?callType=paidContent&targetid=${id}&url=${encodeURIComponent(location.href)}&from=${from}`
}
/**
 *
 * @param {number} id bibi提问id
 */
export const goBibiPage = (id) => {
  const from = getFrom()
  location.href = `${APP_OPEN_URL}bibiDetail?callType=paidContent&targetid=${id}&url=${encodeURIComponent(location.href)}&from=${from}`
}
/**
 *
 * @param {number} id 投票id
 */
export const goVotePage = (id) => {
  const from = getFrom()
  location.href = `${APP_OPEN_URL}voteDetail?callType=paidContent&targetid=${id}&url=${encodeURIComponent(location.href)}&from=${from}`
}
/**
 *
 * @param {number} id 订阅号id
 */
export const goSubscribePage = (id) => {
  const from = getFrom()
  location.href = `${APP_OPEN_URL}subscribeDetail?callType=paidContent&targetid=${id}&url=${encodeURIComponent(location.href)}&from=${from}`
}
/**
 * @param {number} id 订阅号内容id
 */
export const goSubscribeDetail = (id) => {
  const from = getFrom()
  location.href = `${APP_OPEN_URL}subscribeContentDetail?callType=paidContent&targetid=${id}&url=${encodeURIComponent(location.href)}&from=${from}`
}
/**
 *
 * @param {string} id 课单id
 * @param {number} type 课单type
 * @param {string} targetUserId 课单targetUserId
 */
export const goCourseList = (id, type, targetUserId) => {
  const from = getFrom()
  location.href = `${APP_OPEN_URL}courseOrderDetail?id=${id}&type=${type}&targetid=${targetUserId}&from=${from}`
}

export const goPurchased = (from) => {
  location.href = `${APP_OPEN_URL}purchased?callType=paidContent&url=${encodeURIComponent(location.href)}&from=${from}`
}

/**
 * 用户个人主页
 * @param {string} userId
 */
export const goPersonalHome = (userId) => {
  const from = getFrom()
  const inAPP = inApp()
  if (inAPP) window.OpenCourseJSBridge.invoke('pageJumpEvent', { name: 'personalHome', data: { userId } })
  else location.href = `${APP_OPEN_URL}personalHome?userId=${userId}&from=${from}`
}
/**
 * 社区小组详情页
 * @param {number} id
 */
export const goGroupDetail = (id) => {
  const from = getFrom()
  const inAPP = inApp()
  if (inAPP) window.OpenCourseJSBridge.invoke('pageJumpEvent', { name: 'groupDetail', data: { id: +id } })
  else location.href = `${APP_OPEN_URL}groupDetail?id=${id}&from=${from}`
}
/**
 * 社区小组详情页
 * @param {number} id
 */
export const goTopicDetail = (id) => {
  const from = getFrom()
  const inAPP = inApp()
  if (inAPP) window.OpenCourseJSBridge.invoke('pageJumpEvent', { name: 'communityTopicDetail', data: { id: +id } })
  else location.href = `${APP_OPEN_URL}communityTopicDetail?id=${id}&from=${from}`
}
/**
 * 社区广场
 */
export const goCommunityHome = (pfrom) => {
  const from = pfrom || getFrom()
  const inAPP = inApp()
  if (inAPP) window.OpenCourseJSBridge.invoke('pageJumpEvent', { name: 'communityHome' })
  else location.href = `${APP_OPEN_URL}communityHome?from=${from}`
}
/**
 * 社区动态详情
 * @param {number} id
 */
export const goIdeaPage = (id, pfrom) => {
  const from = pfrom || getFrom()
  const inAPP = inApp()
  if (inAPP) window.OpenCourseJSBridge.invoke('pageJumpEvent', { name: 'ideaDetail', data: { id: +id } })
  else location.href = `${APP_OPEN_URL}ideaDetail?id=${id}&from=${from}`
}
/**
 * 用户参与活动列表
 * @param {string} userId
 * @param {string} avatar 用户头像
 */
export const goPersonalActivities = (userId, avatar) => {
  const from = getFrom()
  const inAPP = inApp()
  if (inAPP) window.OpenCourseJSBridge.invoke('pageJumpEvent', { name: 'personalActivities', data: { userId, avatar } })
  else location.href = `${APP_OPEN_URL}homePage?from=${from}`
}

/**
 *
 * @param {string} planKey 计划ID
 */
 export const goPlanDetailPage = (planKey, pfrom) => {
  const from = pfrom || getFrom()
  const inAPP = inApp()
  // iOS 跳转兼容
  let pageType = ''
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    pageType = '253'
  } else {
    pageType = 'studyPlan'
  }
  if (inAPP) window.OpenCourseJSBridge.invoke('pageJumpEvent', { name: 'studyPlan', data: {  id: planKey } })
  else location.href = `${APP_OPEN_URL}${pageType}?callType=paidContent&id=${planKey}&from=${from}`
}

/**
 * 首页推荐卡片详情页
 * @param {number} moduleId 模块ID
 * @param {number} moduleType 模块类型
 */
 export const goRecommendCardDetail = (moduleId, moduleType, cardType, pfrom) => {
  const from = pfrom || getFrom()
  const inAPP = inApp()
  if (inAPP) window.OpenCourseJSBridge.invoke('pageJumpEvent', { name: 'recommendCardDetail', data: {  moduleId, moduleType, cardType } })
  else location.href = `${APP_OPEN_URL}recommendCardDetail?callType=paidContent&moduleId=${moduleId}&moduleType=${moduleType}&cardType=${cardType}&from=${from}`
}

// 获取元素的offset对象
export const getOffset = (el) => {
  const offset = { offsetTop: 0, offsetLeft: 0 }
  while (el) {
    offset.offsetTop += el.offsetTop
    offset.offsetLeft += el.offsetLeft
    el = el.offsetParent
  }
  return offset
}
export const isWx = () => {
  return /MicroMessenger/i.test(navigator.userAgent)
}
// 写cookies

// cookies
export const docCookies = {
  getItem: function (sKey) {
    // eslint-disable-next-line no-useless-escape
    return decodeURIComponent(document.cookie.replace(new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1')) || null
  },
  setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    // eslint-disable-next-line no-useless-escape
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false }
    var sExpires = ''
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires = vEnd === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : '; max-age=' + vEnd
          break
        case String:
          sExpires = '; expires=' + vEnd
          break
        case Date:
          sExpires = '; expires=' + vEnd.toUTCString()
          break
      }
    }
    document.cookie = encodeURIComponent(sKey) + '=' + encodeURIComponent(sValue) + sExpires + (sDomain ? '; domain=' + sDomain : '') + (sPath ? '; path=' + sPath : '') + (bSecure ? '; secure' : '')
    return true
  },
  setItem2: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    // eslint-disable-next-line no-useless-escape
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false }
    var sExpires = ''
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires = vEnd === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : '; max-age=' + vEnd
          break
        case String:
          sExpires = '; expires=' + vEnd
          break
        case Date:
          sExpires = '; expires=' + vEnd.toUTCString()
          break
      }
    }
    document.cookie = encodeURIComponent(sKey) + '=' + sValue + sExpires + (sDomain ? '; domain=' + sDomain : '') + (sPath ? '; path=' + sPath : '') + (bSecure ? '; secure' : '')
    return true
  },
  removeItem: function (sKey, sPath, sDomain) {
    if (!sKey || !this.hasItem(sKey)) { return false }
    document.cookie = encodeURIComponent(sKey) + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' + (sDomain ? '; domain=' + sDomain : '') + (sPath ? '; path=' + sPath : '')
    return true
  },
  hasItem: function (sKey) {
    // eslint-disable-next-line no-useless-escape
    return (new RegExp('(?:^|;\\s*)' + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=')).test(document.cookie)
  },
  keys: /* optional method: you can safely remove it! */ function () {
    // eslint-disable-next-line no-useless-escape
    var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '').split(/\s*(?:\=[^;]*)?;\s*/)
    for (var nIdx = 0; nIdx < aKeys.length; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]) }
    return aKeys
  },
  /**
   * 获取从当前子域到根域的所有域名（不包括当前子域，因为当前子域的 cookie 只能通过不指定 domain 来清除）
   *
   */
  _getHostAry: function () {
    const host = window.location.host
    const hostAtom = host.split('.')

    let baseHostAtomAry = []
    let baseHostAry = []
    hostAtom.reverse().forEach((key, index) => {
      baseHostAtomAry.push(key)
      if (index >= 1) {
        const copy = JSON.parse(JSON.stringify(baseHostAtomAry))
        const curHost = `.${copy.reverse().join('.')}`
        baseHostAry.push(curHost)
      }
    })

    // 去掉最后一位，清除的时候不设置 domain 就默认去当前 domain
    baseHostAry.pop()
    return baseHostAry
  },
  // path 需要精确匹配，包括 /
  // 逻辑同 _getHostAry
  _getPathAry: function () {
    const path = location.pathname
    const pathAtom = path.split('/')
    pathAtom.shift()

    let basePathAtomAry = []
    let basePathAry = ['/']
    pathAtom.forEach(item => {
      basePathAtomAry.push(item)
      const curBasePath = '/' + basePathAtomAry.join('/')
      basePathAry.push(curBasePath)
      basePathAry.push(curBasePath + '/')
    })
    // 去掉最后两位，清除的时候不设置 path 就默认去当前 path
    basePathAry.pop()
    basePathAry.pop()
    return basePathAry
  },

  // 清除cookie
  clear: function () {
    // 获取从当前到根所有路径上的 domain 和 path
    const hostAry = this._getHostAry()
    const pathAry = this._getPathAry()

    var rs = document.cookie.match(new RegExp('([^ ;][^;]*)(?=(=[^;]*)(;|$))', 'gi'))
    // 删除当前域下所有能取到的cookie
    for (var i in rs) {
      const curKey = rs[i]

      hostAry.forEach(domain => {
        pathAry.forEach(path => {
          document.cookie = `${curKey}=;expires=Mon, 26 Jul 1997 05:00:00 GMT;domain=${domain};path=${path}; `

          // 不指定 domain，每个 path 来一遍
          document.cookie = `${curKey}=;expires=Mon, 26 Jul 1997 05:00:00 GMT;path=${path}; `
        })
        // 不指定 path，每个 domain 来一遍
        document.cookie = `${curKey}=;expires=Mon, 26 Jul 1997 05:00:00 GMT;domain=${domain}`
      })
    };
    console.log('清除后的 document.cookie:', document.cookie)
  }

}
export const isAndroid = navigator.userAgent.match(/Android/i)

export const isSupportWebp = async () => {
  if (!window.localStorage || typeof localStorage !== 'object') {
    return
  }
  const name = 'webpAvailable'
  if (!localStorage.getItem(name)) {
    let img = document.createElement('img')
    img.src = 'data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAsAAAABBxAREYiI/gcAAABWUDggGAAAADABAJ0BKgEAAQABABwlpAADcAD+/gbQAA=='
    return new Promise((resolve) => {
      img.onload = function () {
        try {
          localStorage.setItem(name, '1')
        } catch (ex) {
        }
        resolve(true)
      }
      img.onerror = function () {
        try {
          localStorage.setItem(name, '0')
        } catch (ex) {
        }
        resolve(false)
      }
    })
  } else {
    return localStorage.getItem(name) !== '0'
  }
}

export const formatTime = (sec) => {
  let h = sec / 3600 | 0
  let m = sec % 3600 / 60 | 0
  let s = sec % 3600 % 60 | 0
  return `${h ? h + ':' : ''}${m}:${s < 10 ? '0' + s : s}`
}
export const setNOSImg = (url, config) => {

}
const nosImageRex = /(^https?:\/\/[^.]+\.nosdn[^?]+)\??(.*$)?/i
/**
 * 转换nos图片，支持自定义配置项
 * @param {String} url
 * @param {Object} config
 * @param {Boolean} webp
 * @return {String}
 */
export function nosImage (url, config, webp) {
  const exec = nosImageRex.exec(url)
  if (!exec) {
    return url
  }
  const { 1: originUrl, 2: sourceConfigStr } = exec
  const targetUrl = originUrl.replace(/^https?:\/\//i, 'https://plus-')
    .replace('nosdn.127.net', 'ws.126.net')
  // eslint-disable-next-line no-mixed-operators
  if (!config && !webp || /.gif$/gi.test(originUrl)) {
    return `${targetUrl}?${sourceConfigStr}`
  }
  const targetConfig = {}
  if (config) {
    Object.assign(targetConfig, config)
  } else {
    let sourceConfig = {}
    if (sourceConfigStr) {
      sourceConfig = sourceConfigStr.split('&').reduce((obj, item) => {
        const query = item.split('=')
        obj[query[0]] = query[1]
        return obj
      }, {})
    }
    Object.assign(targetConfig, sourceConfig)
  }

  if (webp) {
    targetConfig.type = 'webp'
  }

  const configList = Object.keys(targetConfig)
  if (configList.length) {
    targetConfig.imageView = undefined
    const query = configList.reduce(
      (str, key) => `${str}&${key}=${targetConfig[key]}`, 'imageView')
    return `${targetUrl}?${query}`
  }
  return targetUrl
}
