import openPage from './open/page'
import wxPage from './wx/page'
import htmlPage from './html/page'
import Browser from '@/utils/browser'
// 所在环境
let webevn
if (Browser.isOpenApp() || Browser.isNewsSdk()) {
  webevn = 'open'
} else if (Browser.isWechat()) {
  webevn = 'wx'
} else {
  webevn = 'html'
}
// 适配层
const adaper = {
  // app webview里
  open: {
    page: openPage
  },
  // 微信
  wx: {
    page: wxPage
  },
  // 移动web端
  html: {
    page: htmlPage
  }
}
export default adaper[webevn]
