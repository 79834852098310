import { isAndroid, getAppVersion, versionCompare } from '@/utils/browser'
let OpenCourseJSBridge = window.OpenCourseJSBridge
if (!OpenCourseJSBridge) {
  document.addEventListener('OpenCourseJSBridgeReady', () => {
    OpenCourseJSBridge = window.OpenCourseJSBridge
    OpenCourseJSBridge.invoke =
      OpenCourseJSBridge.invoke === 'function'
        ? OpenCourseJSBridge.invoke
        : OpenCourseJSBridge.invoke
  })
}
let uniqueKey = (function () {
  let uid = 0
  return function () {
    return uid++
  }
})()
export default {
  back () {
    OpenCourseJSBridge.invoke('closeWebview')
  },
  /**
   *
   * @param {Function} cb 监听android物理返回键
   */
  linstenBack (cb) {
    window.eventBack = cb
    // OpenCourseJSBridge.on('back', cb)
  },
  getUserInfo (param = {}) {
    const generatedCallId = uniqueKey()
    param.callback = 'cbUserInfo_' + generatedCallId
    OpenCourseJSBridge.invoke('userInfo', param)

    return new Promise(resolve => {
      OpenCourseJSBridge.on(param.callback, cbParam => {
        resolve(cbParam)
      })
    })
  },
  // 获取设备信息
  getDeviceInfo (param = {}) {
    const generatedCallId = uniqueKey()
    param.callback = 'cbDeviceInfo_' + generatedCallId
    OpenCourseJSBridge.invoke('deviceInfo', param)

    return new Promise(resolve => {
      OpenCourseJSBridge.on(param.callback, cbParam => {
        resolve(cbParam)
      })
    })
  },
  // 获取网络状态
  getNetworkInfo (param = {}) {
    const generatedCallId = uniqueKey()
    param.callback = 'cbNetworkInfo_' + generatedCallId
    OpenCourseJSBridge.invoke('networkInfo', param)

    return new Promise(resolve => {
      OpenCourseJSBridge.on(param.callback, cbParam => {
        resolve(cbParam)
      })
    })
  },
  setTitle (title) {
    document.title = title
    OpenCourseJSBridge.invoke('title', { title })
  },
  /**
   * 跳转到原生页面
   * @param {string} name 页面的名字 参考 http://doc.ws.netease.com/pages/viewpage.action?pageId=15371476
   * @param {Object} params 页面参数
   */
  goAppPage (name, params = {}) {
    // name='payComboDetail' params{ comBoId:组合购ID}
    // android低版本channel字段有问题
    if (isAndroid() && !versionCompare(getAppVersion(), '6.4.2')) {
      delete params.channel
    }
    OpenCourseJSBridge.invoke('pageJumpEvent', { name, data: params })
  },
  // 图片滑动浏览注入图片
  picDetailArray (pictures) {
    OpenCourseJSBridge.invoke('picDetailArray', {
      pictures
    })
  },
  picTapAction (index, noImgPos) {
    // noImgPos 默认显示，2不显示
    OpenCourseJSBridge.invoke('picTapAction', { index: index, noImgPos: noImgPos })
  },
  // app里无需判断是否需要登录
  signIn () {
    OpenCourseJSBridge.invoke('pageJumpEvent', {
      name: 'login',
      callback: 'loginback'
    })
    return new Promise(resolve => {
      OpenCourseJSBridge.on('loginback', cbParam => {
        resolve(cbParam)
      })
    })
  },
  /**
   * 直接分享
   * @param {Object} param
   * {
   *   shareTo: weixinFriend 微信朋友 weixinCircle：微信朋友圈
   * }
   * @returns
   */
  shareTo (param = {}) {
    OpenCourseJSBridge.invoke('shareTo', param)
    return new Promise(resolve => {
      resolve({})
    })
  },
  /**
   * 获取0元拼团兑换码
   */
  getPinCode (param = {}) {
    const generatedCallId = uniqueKey()
    param.callback = `callback_${generatedCallId}`
    OpenCourseJSBridge.invoke('pinCode', param)
    return new Promise(resolve => {
      OpenCourseJSBridge.on(param.callback, cbParam => {
        resolve(cbParam)
      })
    })
  },
  /**
   * h5是否拦截android返回按钮
   * @param param
   * {
   *  intercept 0 不拦截 1拦截
   *  callback
   * }
   */
  interceptBack (param = {}) {
    OpenCourseJSBridge.invoke('interceptBack', param)
    window.eventBack = param.cb
  },
  setWebviewStyle (param = {}) {
    const generatedCallId = uniqueKey()
    param.callback = 'callback_' + generatedCallId
    OpenCourseJSBridge.invoke('setWebviewStyle', param)
    return new Promise(resolve => {
      OpenCourseJSBridge.on(param.callback, cbParam => {
        resolve(cbParam)
      })
    })
  },
  /**
   * 打开新页面(如文章来源)
   * @param {string} url 页面地址
   */
  tapSourceAction (url) {
    OpenCourseJSBridge.invoke('tapSourceAction', {
      url: url
    })
  },
  /**
   * 绑定三方账号
   * @param param
   */
  bindAccount (param = {}) {
    const generatedCallId = uniqueKey()
    param.callback = 'callback_' + generatedCallId
    OpenCourseJSBridge.invoke('bindAccount', param)
    return new Promise(resolve => {
      OpenCourseJSBridge.on(param.callback, cbParam => {
        resolve(cbParam)
      })
    })
  },
  /**
   * 第三方登录
   * @param {Object} param
   * {
   *  platform: 1 微信 2 微博
   * }
   * @returns promise
   * {
   *  status:0:登录失败 1:登录成功
   *   rawData:微信/微博返回原始数据
   *  }
   */
  thirdLogin (param = {}) {
    const generatedCallId = uniqueKey()
    param.callback = 'cbThirdLogin_' + generatedCallId
    OpenCourseJSBridge.invoke('thirdLogin', param)
    return new Promise(resolve => {
      OpenCourseJSBridge.on(param.callback, cbParam => {
        resolve(cbParam)
      })
    })
  },
  /**
   * 显示/隐藏右上角分享按钮
   * @param {number} type 0|1 (隐藏|显示)
   */
  showShareBtnFun (type) {
    if (isNaN(type)) {
      type = 1
    }
    OpenCourseJSBridge.invoke('shareBtn', {
      type: type
    })
  },
  /**
   * 显示分享组件
   */
  showShare () {
    OpenCourseJSBridge.invoke('showshare')
  },
  /**
   * 弹出一个确认/取消框
   * @param {object} param {title:标题,okBtn:确认按钮名称,cancelBtn:取消按钮名称}
   * @returns {Promise} {action:true/false}   (点击确认/点击取消)
   */
  showDialog (param) {
    if (!param) {
      console.error('参数必须')
    } else {
      param.callback = 'callback_' + uniqueKey()
      OpenCourseJSBridge.invoke('showDialog', param)

      return new Promise((resolve) => {
        OpenCourseJSBridge.on(param.callback, (cbParam) => {
          // FIXME: 实际上ios返回的是数字，安卓返回的action是字符串，简直搞事情……
          let result = {}
          if (!isNaN(cbParam)) {
            result.action = !!cbParam
          } else if (typeof cbParam.action === 'string') {
            result.action = cbParam.action === 'true'
          }
          resolve(result)
        })
      })
    }
  },

  /**
   * 关闭WebView
   */
  closeWebPage () {
    OpenCourseJSBridge.invoke('closeWebview')
    OpenCourseJSBridge.invoke('closeWebPage')
  },
  /**
   * 保存图片
   * @param {Object} param {
   *  type:  0:url,1:base64,
      picSource: url/base64
   * }
   */
  savePic (param = {}) {
    const generatedCallId = uniqueKey()
    param.callback = 'cbSavePic_' + generatedCallId
    OpenCourseJSBridge.invoke('savePic', param)
    return new Promise(resolve => {
      OpenCourseJSBridge.on(param.callback, cbParam => {
        resolve(cbParam)
      })
    })
  },
  /**
   * 打开微信
   */
  openWechat () {
    OpenCourseJSBridge.invoke('openWechat')
  },
  /**
   * 微信分享图片
   * @param {Object} param {
   *  shareChannle：分享渠道， wechat_friend好友，wechat_cricle朋友圈
      bgImgUrl：背景图
      qrCodeContent：二维码内容
      nickName：昵称
   * }
   */
  shareImgToWechat (param = {}) {
    OpenCourseJSBridge.invoke('shareImgToWechat', param)
    return new Promise(resolve => {
      resolve({})
    })
  },
  /**
   * @param {Object} showTips
   * {
   *  desc: '提示信息', duration: 2000(单位ms)
   * }
   */
  showTips (param = { desc: '提示信息', duration: 2000 }) {
    OpenCourseJSBridge.invoke('showTips', param)
  },
  /**
   * 微信分享base64图片
   * @param {Object} param
   * {
   *  shareChannle：分享渠道， wechat_friend好友，wechat_cricle朋友圈
      baseImg： base64图片
   * }
   */
  shareBaseImgToWechat (param = {}) {
    const generatedCallId = uniqueKey()
    param.callback = 'cbShareBaseImgToWechat_' + generatedCallId
    OpenCourseJSBridge.invoke('shareBaseImgToWechat', param)
    return new Promise(resolve => {
      OpenCourseJSBridge.on(param.callback, cbParam => {
        resolve(cbParam)
      })
    })
  },
  /**
   * 回复 - 展示评论框
   * @param {Object} param
   * {
   *  contentId： 内容主题
   *  commentId： 评论ID
   *  replyId： 回复ID
   *  nick： 昵称
   *  type： 4-文章，5-图集
   * }
   */
  replyComment (param = {}) {
    const generatedCallId = uniqueKey()
    param.callback = 'cbReplyComment_' + generatedCallId
    OpenCourseJSBridge.invoke('comment', param)
    return new Promise(resolve => {
      OpenCourseJSBridge.on(param.callback, cbParam => {
        resolve(cbParam)
      })
    })
  },
  /**
   * 写评论 - 展示评论框
   * @param {Object} param
   * {
   *  contentId： 评论ID
   *  type： 4-文章，5-图集
   * }
   */
  sendComment (param = {}) {
    const generatedCallId = uniqueKey()
    param.callback = 'cbSendComment_' + generatedCallId
    OpenCourseJSBridge.invoke('comment', param)
    return new Promise(resolve => {
      OpenCourseJSBridge.on(param.callback, cbParam => {
        resolve(cbParam)
      })
    })
  },
  /**
   * 退出登录
   * @returns
   */
  logout (param = {}) {
    const generatedCallId = uniqueKey()
    param.callback = `cbLogout_${generatedCallId}`
    OpenCourseJSBridge.invoke('logout', param)
    return new Promise((resolve) => {
      OpenCourseJSBridge.on(param.callback, (cbParam) => {
        resolve(cbParam)
      })
    })
  },
  /**
   * 跳转指定视频并指定播放时间
   * @returns
   */
   noteSeekTime (param = {}) {
    console.log('noteSeekTime', param)
    OpenCourseJSBridge.invoke('noteSeekTime', param)
  },
  /**
   * 跳转指定笔记编辑
   * @returns
   */
   noteEdit (param = {}) {
    console.log('noteEdit', param)
    OpenCourseJSBridge.invoke('noteEdit', param)
  },
  /**
   * 打开知识点菜单
   * @returns
   */
   openPointMenu (param = {}) {
    console.log('openPointMenu', param)
    OpenCourseJSBridge.invoke('openPointMenu', param)
  },
  /**
   * 客户端埋点
   * @returns
   */
   eventTracker (param = {}) {
    console.log('eventTracker', param)
    OpenCourseJSBridge.invoke('eventTracker', param)
  }
}
