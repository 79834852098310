import request from './request'
import apis from './apis'

/**
 * api集合
 */
const apiCollection = {}
for (let key in apis) {
  apis[key]
  apiCollection[key] = getApiInstance(apis[key])
}

function getApiInstance (config = {}) {
  return (params, _config = {}) => {
    const { url: _urlName } = _config
    const { url: urlPrefix, urlName: __urlName } = config
    const urlName = _urlName || __urlName || ''
    const absoluteUrl = /^http/gi.test(urlName)
    const url = `${absoluteUrl ? '' : urlPrefix}${urlName}`
    const { type, moreInfo } = Object.assign({}, config, _config, { url })
    return request.send(url, type, params, moreInfo)
  }
}

export default apiCollection
