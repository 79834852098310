export default function (app) {
  const data = {
    // ${binding.value.id}: ${binding.vaue}
  }
  const iso = new IntersectionObserver((entries) => {
    entries.forEach(({ intersectionRatio, target }) => {
      if (intersectionRatio > 0) {
        try {
          const id = target.getAttribute('exposure-tracker-id')
          iso.unobserve(target)
          if (!data.hasOwnProperty(id)) return
          const { eventName, name } = data[id]
          delete data[id]
          app.config.globalProperties.$clickTracker(eventName, name)
        } catch (e) {
          console.error(e)
        }
      }
    })
  })
  app.directive('exposuretracker', {
    mounted (el, binding) {
      const id = binding.value?.id
      if (!id) {
        console.error('v-exposuretracker value.id is ', id)
        return
      }
      data[id] = binding.value
      el.setAttribute('exposure-tracker-id', id)
      iso.observe(el)
    },
    beforeUnmount (el, binding) {
      const id = binding.value?.id
      if (!id || !data[id]) return
      delete data[id]
      iso.unobserve(el)
    }
  })
}