import { addZero } from '@/utils'
import dayjs from 'dayjs'

export default {
  install (Vue) {
    Object.assign(Vue.config.globalProperties, {
      formatTime: (value, format) => {
        if (value) {
          return dayjs(value).format(format || 'YYYY-MM-DD HH:mm:ss')
        } else {
          return ' '
        }
      },
      getTime: (value, separator) => {
        separator = separator || '.'
        const _date = new Date(value)
        return `${_date.getFullYear()}${separator}${_date.getMonth() + 1}${separator}${_date.getDate()}`
      },
      /**
       * 小于3分钟：显示“刚刚更新”； 3分钟<=N<60分钟：显示“N分钟前更新”； 当天：只显示具体时间（精确到分），格式为“hh：mm更新”，如“14：17更新”； 昨天：显示“昨天更新”； 前天：显示“前天更新”； 前天以前：显示年月日，格式为“yyyy/mm/dd”，如“2017/02/18”
       */
      dbCreateTime: (value) => {
        const _nowTime = new Date().getTime()
        // 今天零时的时间戳
        const _todayZeroTime = new Date(new Date().setHours(0, 0, 0, 0)).getTime()
        const _time = parseInt((_nowTime - value) / 1000)
  
        if (_time < 180) {
          return '刚刚更新'
        }
        if (_time < 3600) {
          return parseInt(_time / 60) + '分钟前更新'
        }
        const pushDate = new Date(value)
        if (value > _todayZeroTime) {
          const _m = addZero(pushDate.getMinutes())
          const _h = addZero(pushDate.getHours())
          return `${_h}:${_m}`
        }
        // 前天零时的时间
        const _beforeYesterday = _todayZeroTime - 86400 * 1000 * 2
        if (value < _beforeYesterday) {
          const _d = addZero(pushDate.getDate())
          const _month = addZero(pushDate.getMonth() + 1)
          const _y = pushDate.getFullYear()
          return `${_y}/${_month}/${_d}`
        }
        // 昨天0时时间
        const _yesterdayZero = _todayZeroTime - 86400 * 1000
        if (value < _yesterdayZero) {
          return '前天'
        }
        if (value < _todayZeroTime) {
          return '昨天'
        }
  
        return value
      },
      communityDateFormat: (value) => {
        let cur = new Date(value)
        let curYear = cur.getFullYear()
        let curMonth = cur.getMonth() + 1
        let curDate = cur.getDate()
        curMonth = `${curMonth < 10 ? '0' : ''}${curMonth}`
        curDate = `${curDate < 10 ? '0' : ''}${curDate}`
        let now = new Date()
        let year = now.getFullYear()
        let nowTime = now.getTime()
        let dayTime = 1000 * 60 * 60 * 24
        let todayTime = nowTime % (1000 * 60 * 60 * 24)
        let yesterdayStar = nowTime - todayTime - dayTime
        let t = nowTime - value
        if (nowTime - value < 60 * 1000) return '刚刚'
        else if (t < 60 * 60 * 1000) return `${Math.floor(t / (60 * 1000))}分钟前`
        else if (t < 24 * 60 * 60 * 1000) return `${Math.floor(t / (60 * 60 * 1000))}小时前`
        else if (value > yesterdayStar) return '昨天'
        else if (curYear === year) return `${curMonth}-${curDate}`
        else return `${curYear}-${curMonth}-${curDate}`
      },
      /**
       * 动态时间
       * 1.动态客态时间显示规则：
       * 当日直接显示时间如09:55、昨天直接显示“昨天”、今年显示：09.11、非今年显示：1998.12.20
       * 2.动态详情和列表字数最多1000字
       */
      // new Date(2018,0,1).getTime()
      momentTime: (value, isJustnow, isShowSeconds) => {
        if (!value) {
          return ''
        } // const _nowTime = new Date().getTime()
        // 今天零时的时间戳
        const _todayZeroTime = new Date(new Date().setHours(0, 0, 0, 0)).getTime()
        // const _time = parseInt((_nowTime - value) / 1000)
  
        const pushDate = new Date(value)
        if (isJustnow && new Date().getTime() - value < 60 * 1000) {
          return '刚刚'
        }
        if (value > _todayZeroTime) {
          const _s = addZero(pushDate.getSeconds())
          const _m = addZero(pushDate.getMinutes())
          const _h = addZero(pushDate.getHours())
          return isShowSeconds ? `${_h}:${_m}:${_s}` : `${_h}:${_m}`
        }
        // 昨天0时时间
        const _yesterdayZero = _todayZeroTime - 86400 * 1000
        if (value > _yesterdayZero && value < _todayZeroTime) {
          return '昨天'
        }
        // 今年1月1日0时时间戳
        const _nowyear = new Date().getFullYear()
  
        const _nowyeartime = new Date(_nowyear, 0, 1).getTime()
        const _d = addZero(pushDate.getDate())
        const _month = addZero(pushDate.getMonth() + 1)
        const _y = pushDate.getFullYear()
        // 今年以前
        if (value < _nowyeartime) {
          return `${_y}.${_month}.${_d}`
        } else {
          return `${_month}.${_d}`
        }
      },
      zero: (value) => {
        return addZero(value)
      },
      interestNum: (value) => {
        if (!value) return 0
        if (value < 10000) {
          return value
        } else {
          return (value / 10000).toFixed(1) + '万'
        }
      },
      changeTimeFormat: (value) => {
        return (value || '').replace(`''`, '').replace(`' `, ':') || '00:00'
      },
      // peopleNum: (val) => {
      //   if (val < 11) {
      //     return '新上架'
      //   }
      //   if (val < 100000) {
      //     return `${val}人订阅`
      //   }
      //   if (val > 99990000) {
      //     return '9999万人订阅'
      //   }
      //   return `${(val / 10000).toFixed(1)}万人订阅`
      // },
      playTime: (value) => {
        const s = value % 60 | 0
        const m = value / 60 % 60 | 0
        const h = value / 3600 | 0
        return `${h ? `${addZero(h)}:` : ''}${addZero(m)}:${addZero(s)}`
      },
  
      mediaLength: (value) => {
        if (value < 60) {
          return `${value}秒`
        }
        return `${Math.ceil(value / 60)}分钟`
      },

      timeLength: (value) => {
        const v = +value > 0 ? +value : 0
        // 时
        const h = Math.floor(v / (60*60))
        // 分
        const m = Math.floor(v % (60*60) / 60)
        return [h > 0 ? h + '小时' : '', m > 0 ? m + '分' : ''].join('') || '未开始学习'
      },

      ahexToHexa: (ahex) => {
        try {
          const [prefix, a0, a1, ...hex] = ahex
          return [prefix, ...hex, a0, a1].join('')
        } catch (error) {
          console.log(ahex, error)
        }
      },

      toHttps: (url) => url.replace?.(/^http:/, 'https:'),

      useImageView: (url, str) => {
        const httpsUrl = url.replace?.(/^http:/, 'https:')
        return httpsUrl.indexOf?.('?') > -1 ? httpsUrl : (httpsUrl + '?' + str)
      },

      defaultImageUrl: 'https://open-image.ws.126.net/ab8196eea10847b7be697d069963a597.png',

      defaultAvatarUrl: 'https://open-image.ws.126.net/8801c1df751f4cd49f23c88ab0356c89.png',
    })
  }
}
