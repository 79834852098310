import { createStore } from 'vuex'

import modules from './modules'
import actions from './actions'
import mutations from './mutations'
import state from './store'

export default createStore({
  state,
  actions,
  mutations,
  modules,
  strict: process.env.VUE_APP_MOBILE_ENV !== 'production'
})
