import service from '@/service'
import toast from '@/utils/toast'

const SET_NOTE_DATA = 'SET_NOTE_DATA' // 设置笔记列表
const SET_NOTE_LOADING = 'SET_NOTE_LOADING' // 设置笔记加载状态
const SET_NOTE_LIST = 'SET_NOTE_LIST' // 设置笔记加载状态
const DEL_NOTE_BY_ID = 'DEL_NOTE_BY_ID' // 设置笔记加载状态
const SET_FIRST_FETCH_LOADED = 'SET_FIRST_FETCH_LOADED' // 设置首次加载状态

const state = {
  firstFetchloaded: false, // 是否第一次加载
  noteId: '',
  noteData: {}, // 当前笔记
  noteLoading: false, // 笔记加载状态
  noteList: [],
  lastItemLoaded: false
}

const getters = {
  // 是否存在审核中或者审核不通过的笔记
  hasUnpassNote: state => state.noteData.noteInfo && state.noteData.noteInfo.verifyStatus === 0 ? false : true,
  noteInfo: state => state.noteData.noteInfo || {},
  firstItemMid: state => state.noteData.firstItemMid,
  firstItemLoaded: state => state.noteList[0] && state.noteList[0].mid === state.noteData.firstItemMid,
  noteListBottomItemMid: state => state.noteList[state.noteList.length - 1] && state.noteList[state.noteList.length - 1].mid || '',
}

const mutations = {
  [SET_NOTE_DATA]: (state, noteData) => {
    state.noteId = noteData.noteInfo.id
    state.noteData = noteData
  },
  [SET_FIRST_FETCH_LOADED]: (state, firstFetchloaded) => {
    state.firstFetchloaded = firstFetchloaded
  },
  [SET_NOTE_LOADING]: (state, noteLoading) => {
    state.noteLoading = noteLoading
  },
  [SET_NOTE_LIST]: (state, noteData) => {
    const {preItemList = [], item = null, nextItemList = []} = noteData
    if (state.noteList.length === 0) {
      if (item) {
        state.noteList = [...preItemList, item, ...nextItemList]
      } else {
        state.noteList = [...preItemList, ...nextItemList]
      }
    } else {
      // 插入或替换 item 所在位置的前后可能存在的5条数据
      const index = state.noteList.findIndex(item => item.mid === noteData.item.mid)
      const spliceStart = index - 5 > 0 ? index - 5 : 0
      const spliceEnd = index + 6 < state.noteList.length ? index + 6 : state.noteList.length
      state.noteList.splice(spliceStart, spliceEnd - spliceStart, ...preItemList, item, ...nextItemList)

      // 如果没有nextItemList或nextItemList小于5条时说明最后一条内容已经加载
      if (!nextItemList || nextItemList.length < 5) {
        state.lastItemLoaded = true
      }
    }
  },
  [DEL_NOTE_BY_ID]: (state, id) => {
    state.noteList = state.noteList.filter(item => item.id !== id)
  }
}

const actions = {
  // 获取笔记列表
  async fetchStudynoteNearby({ commit, state: { noteLoading } }, {noteId, mid}) {
    if (noteLoading) return
    commit(SET_NOTE_LOADING, true)
    try {
      let res = await service.getStudynoteNearby({ noteId, mid })
      commit(SET_NOTE_DATA, res.data)
    } catch (e) {
      toast.show(e.message)
    } finally {
      commit(SET_NOTE_LOADING, false)
    }
  },
  // 相较于fetchStudynoteNearby返回上一篇和下一篇，此接口回返回当前笔记的前5篇和后5篇
  async fetchStudynotePart({ commit, state: { noteLoading } }, payload) {
    const { noteId, mid, preview } = payload
    if (noteLoading) return
    commit(SET_NOTE_LOADING, true)
    try {
      let res = await service.getStudynotePart({ noteId, mid, preview })
      commit(SET_NOTE_DATA, res.data)
      commit(SET_NOTE_LIST, res.data)
    } catch (e) {
      toast.show(e.message)
    } finally {
      commit(SET_FIRST_FETCH_LOADED, true)
      commit(SET_NOTE_LOADING, false)
    }
  },
  async deleteStudynote({ commit, state: { noteList, noteId, noteLoading } }, { noteItemId }) {
    try {
      // 查找 noteList中 临近的 noteItemId
      let index = noteList.findIndex(item => item.id === noteItemId)
      const nearByNoteMid = noteList[index - 1] ? noteList[index - 1].mid : noteList[index + 1] ? noteList[index + 1].mid : ''
      await service.deleteStudynote({ noteItemId })
      commit(DEL_NOTE_BY_ID, noteItemId)
      
      if (noteLoading) return
      commit(SET_NOTE_LOADING, true)
      if (nearByNoteMid) {
        const res = await service.getStudynotePart({ noteId, mid: nearByNoteMid })
        commit(SET_NOTE_DATA, res.data)
        commit(SET_NOTE_LIST, res.data)
      }
    } catch (e) {
      toast.show(e.message)
    } finally {
      commit(SET_NOTE_LOADING, false)
    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
