import 'regenerator-runtime'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Filters from '@/utils/oldFilter'
import directiveExposureTracker from '@/utils/directive-exposuretracker'
import alertDialog from '@/utils/alertDialog'
import PublicMethods from '@/utils/PublicMethods'
import './base.less'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

const app = createApp(App)
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dns: 'https://6a0a4811077d4456aee630cd64ddc69e@music-sentry.hz.netease.com/1625',
    integrations: [new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['m.open.163.com', 'testm.open.163.com', 'openapi.ws.netease.com']
    })],
    tracesSampleRate: 1.0,
  })
}
app.use(PublicMethods)
app.config.globalProperties.$alertDialog = alertDialog
app.use(directiveExposureTracker)
app.use(Filters)
app.use(store)
app.use(router)
app.mount('#app')
