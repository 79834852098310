/**
 * 环境检测
 * @author lidouhan@corp.netease.com
 */
const IOS_REG = /iphone|ipad|ipod/i
const IPHONE = /iphone/i
const IPAD = /ipad/i
const ANDROID_REG = /android/i
const ANDROID_MOBILE = /android.*?mobile/i
const OPENCOURSE_APP_REG = /(OpenCourse|vopen)/i
const CHROME = /Chrome/i
const NEWS_APP = /platform=opensdk/i
const IS_IN_NEWS_APP = /NewsApp/i

let userAgent = navigator.userAgent
export let isIOS = function () {
  return IOS_REG.test(userAgent)
}
export let isAndroid = function () {
  return ANDROID_REG.test(userAgent)
}
export let isSafari = function () {
  return /Safari/i.test(userAgent) && !CHROME.test(userAgent)
}
export let isAndroidMobile = function () {
  return ANDROID_MOBILE.test(userAgent)
}
export let isAndroidPad = function () {
  return isAndroid() && !isAndroidMobile()
}
export let isNewsSdk = function () {
  return NEWS_APP.test(location.href)
}

export let isNewsApp = function () {
  return IS_IN_NEWS_APP.test(userAgent)
}

let _hackOpenAppIpad = function () {
  let aspectRatio = window.outerWidth / window.outerHeight
  if (aspectRatio > 0.92 && aspectRatio < 1.08 && window.OpenCourseJSBridge) {
    return true
  } else {
    return false
  }
  // if (window.OpenCourseJSBridge) {
  //   return true
  // } else {
  //   return false
  // }
}

export let isOpenApp = function () {
  return OPENCOURSE_APP_REG.test(userAgent) || _hackOpenAppIpad()
}
export let isMobile = function () {
  return isAndroid() || isIOS()
}
export let isIPhone = function () {
  return IPHONE.test(userAgent)
}
export let isIphoneX = function () {
  return isIPhone() && (window.screen.height === 812 || window.screen.height === 896)
}
export let isIPad = function () {
  return IPAD.test(userAgent)
}
export let isQQBrowser = function () {
  return userAgent.indexOf('mqqbrowser') > -1
}
export let isWechat = function () {
  return /MicroMessenger/i.test(userAgent)
}
export let isWechatMiniProgram = function () {
  return /MicroMessenger/i.test(userAgent) && /miniProgram/i.test(userAgent)
}
export let isWeibo = function () {
  return userAgent.indexOf('weibo') > -1
}
export let isAlipay = function () {
  return /Alipay/i.test(userAgent)
}
export let isAlipayMiniProgram = function () {
  return /Alipay/i.test(userAgent) && /MiniProgram/i.test(userAgent)
}

export let getAndroidVersion = function () {
  if (isAndroid()) {
    let matched = userAgent.match(/Android (\d+).(\d+)/i)
    if (matched && matched[1]) {
      return matched[1]
    }
  }
  return -1
}
export let getIOSVersion = function () {
  if (isIOS()) {
    let matched = userAgent.match(/os (\d+)_(\d)+/i)
    if (matched && matched[1]) {
      return matched[1]
    }
  }
  return -1
}
export let isYixin = function () {
  return /YiXin/i.test(userAgent)
}
export let isQQ = function () {
  return /QQ\//i.test(userAgent)
}
export let getYiXinVersion = function () {
  let isYiXin = navigator.userAgent.match(/YiXin\/(\d{4})/i)
  return (isYiXin && isYiXin[1]) || 0
}
export let isChrome = function () {
  return CHROME.test(userAgent)
}
export let getChromeVersion = function () {
  let isChrome = navigator.userAgent.match(/Chrome\/(\d+)/i)
  return (isChrome && isChrome[1]) || 0
}
export let getAppVersion = () => {
  let clientVersion = navigator.userAgent.match(/OpenCourse_(Android|iPhone|iPad)\/(\d+\.\d+\.\d+)/)
  return clientVersion && clientVersion[2]
}
// 当当前的curVersion>=flagVersion 返回true
export function versionCompare (curVersion, flagVersion) {
  curVersion = curVersion.split('.')
  flagVersion = flagVersion.split('.')
  for (let i = 0; i < curVersion.length; i++) {
    if (curVersion[i] > flagVersion[i]) { return true }
    if (curVersion[i] < flagVersion[i]) { return false }
  }
  return true
}

export default {
  isIOS,
  isNewsSdk,
  isOpenApp,
  isAndroidMobile,
  isAndroidPad,
  isIPhone,
  isIphoneX,
  isIPad,
  isMobile,
  isAndroid,
  isWeibo,
  isSafari,
  isYixin,
  isQQ,
  getIOSVersion,
  getYiXinVersion,
  isQQBrowser,
  isWechat,
  isChrome,
  getChromeVersion,
  getAppVersion,
  versionCompare
}
