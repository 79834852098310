<template>
  <router-view v-slot="{ Component }">
    <keep-alive v-show="$route.meta.keepAlive">
      <component :key="$route.fullPath" :is="Component" v-if="$route.meta.keepAlive"></component>
    </keep-alive>
    <component v-if="!$route.meta.keepAlive" :key="$route.fullPath" :is="Component"></component>
  </router-view>
</template>

<script>
import { docCookies } from './utils/index.js'
import VConsole from 'vconsole'
export default {
  setup() {
    if (!process.env.DEBUGGER && (location.href.indexOf('vconsole') !== -1)) {
      const oScript = document.createElement('script')
      oScript.type = 'text/javascript'
      oScript.onload = function () {
        // eslint-disable-next-line no-new
        new VConsole()
      }
      document.body.appendChild(oScript)
      oScript.src = 'https://static.ws.126.net/163/open/vconsole.js'
    }
  },
  mounted () {
    if (location.href.indexOf('clearcookie') !== -1) {
      setTimeout(() => {
        docCookies.clear()
        this.$toast.show('清除cookie成功')
      }, 2000)
    }
  }
}
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
}
html,
body,
#app {
  background-color: #fff;
}
</style>
