/**
 * 单例, 返回方法, instance作为闭包中存储的单例变量
 */
let __instance__ = (function () {
  let instance
  return (newInstance) => {
    if (!instance) {
      instance = newInstance
    }
    return instance
  }
}())

/**
 * 默认配置参数
 * @type {Object}
 */
const DEFAULT = {
  message: '提示',
  duration: 1800
}

class Toast {
  constructor (options) {
    if (__instance__(null)) {
      return __instance__(null)
    }
    __instance__(this)
    this.initNode()
  }

  /**
   * 将创建的dom放入this中
   */
  initNode () {
    let nodeObj = _createNodes()
    this.toastWrapper = nodeObj.toastWrapper
    this.toastText = nodeObj.toastText
    document.body.appendChild(this.toastWrapper)
  }

  show (options) {
    // 如果只穿了字符串, 则放入message中
    if (options && typeof options !== 'object') {
      this.options = { ...DEFAULT, message: options }
    } else {
      this.options = { ...DEFAULT, ...options }
    }
    if (this.options.message.toLowerCase() === 'network error') {
      return
    }
    this.toastWrapper.classList.remove('hide')
    this.toastText.innerText = this.options.message
    this.afterShow()
  }

  afterShow () {
    clearTimeout(this.tid)
    this.tid = setTimeout(() => {
      clearTimeout(this.tid)
      this.hide()
    }, this.options.duration)
  }
  hide () {
    this.toastWrapper.classList.add('hide')
  }
}

function _createNodes () {
  const toastWrapper = document.createElement('div')
  toastWrapper.classList.add('toast-wrap')
  toastWrapper.classList.add('hide')
  const toastText = document.createElement('div')
  toastText.classList.add('toast-text')

  toastWrapper.appendChild(toastText)

  return {
    toastWrapper: toastWrapper,
    toastText
  }
}

export default new Toast()
