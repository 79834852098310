// import * as types from './mutation-types'
import S from '@/service'

export default {
  async checklogin ({ commit }) {
    try {
      let res = await S.checkLogin()
      if (res.data.status === 1) {
        commit('checklogin', true)
      } else {
        commit('checklogin', false)
      }
      return res
    } catch (e) {
      return Promise.reject(e)
    }
  },
  getList ({ commit, state }, payload) {
    commit('getList', payload)
  },
  changeShow ({ commit, state }, payload) {
    commit('changeShow', payload)
  },
  changePaying ({ commit, state }, payload) {
    commit('changePaying', payload)
  }

}
