<template>
  <div ref="alertDialog" class="mask" v-if="show">
    <div class="box">
      <h5>{{ title }}</h5>
      <p v-if="content">{{ content }}</p>
      <div class="btn-group">
        <a href="javascript:void(0);" class="cancel" @click="cancel">取 消</a>
        <a href="javascript:void(0);" @click="confirm">{{ btnTxt || '确 定' }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      title: '',
      content: '',
      btnTxt: ''
    };
  },
  methods: {
    confirm() {
      this.a_confirm && this.a_confirm();
      this.show = false;
    },
    cancel() {
      this.a_cancel && this.a_cancel();
      this.show = false;
    }
  }
};
</script>

<style lang="less" scoped>
@import url("../common-gb.less");

.mask {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box {
  overflow: hidden;
  position: relative;
  width: 300px;
  padding: 20px 25px;
  background: #ffffff;
  border-radius: 3px;
  &::before {
    content: "";
    position: absolute;
    width: 60vw;
    height: 60vw;
    border-radius: 100%;
    top: -29vw;
    left: -10vw;
    pointer-events: none;
    background: linear-gradient(95deg, #20d67477 0%, #20d67400 90%);
    opacity: .5;
  }
  h5 {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    line-height: 25px;
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: #333;
    line-height: 20px;
    position: relative;
    z-index: 1;
  }
}
.btn-group {
  display: flex;
  justify-content: space-between;
  width: 250px;
  height: 35px;
  margin-top: 25px;
  position: relative;
  z-index: 1;
  a {
    display: block;
    width: 110px;
    text-decoration: none;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 500;
    color: #43b478;
    line-height: 35px;
    text-align: center;
    position: relative;
    .shadow_border(solid 1px #43b478, 3px);
    &.cancel {
      color: #a1a1a1;
      .shadow_border(solid 1px #a1a1a1, 3px);
    }
  }
}
</style>