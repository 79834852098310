import AlertDialog from '../components/Free/GuideBack/Common/AlertDialog.vue';
import {createApp} from 'vue'

const div = document.createElement('div')
div.id = 'alertDialog'
document.body.appendChild(div);
let alert = createApp(AlertDialog)
let alertDom = alert.mount('#alertDialog')

export default (o) => {
  return new Promise((response, reject) => {
    alertDom.title = o.title || '确认';
    alertDom.content = o.content;
    alertDom.btnTxt = o.btnTxt;
    alertDom.show = true;
    
    alertDom.a_confirm = async ()=> {
      response()
    }
    alertDom.a_cancel = async ()=> {
      reject()
    }
  })
}
