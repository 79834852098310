import axios from 'axios'
import NAPI from '@/adapter'
import qs from 'qs'
import { isOpenApp } from '@/utils/browser'

const getType = (data) => {
  return Object.prototype.toString.call(data)
}

// 处理返回数据，给图片增加cdn
const addImgCdn = function (data) {
  const reg = /(http|https):\/\/open-image\.nosdn\.127\.net/ig
  const CDN_URL = location.protocol + '//open-image.ws.126.net'
  if (getType(data) === '[object Object]') {
    let obj = {}
    for (let item in data) {
      if (typeof data[item] === 'object') {
        obj[item] = addImgCdn(data[item])
      } else if (typeof data[item] === 'string' && reg.test(data[item])) {
        obj[item] = data[item].replace(reg, CDN_URL)
      } else {
        obj[item] = data[item]
      }
    }
    return obj
  } else if (getType(data) === '[object Array]') {
    const arr = []
    for (let item of data) {
      if (typeof item === 'object') {
        arr.push(addImgCdn(item))
      } else if (typeof item === 'string' && reg.test(item)) {
        arr.push(item.replace(reg, CDN_URL))
      } else {
        arr.push(item)
      }
    }
    return arr
  } else if (typeof data === 'string') {
    let str
    if (reg.test(data)) {
      str = data.replace(reg, CDN_URL)
    } else {
      str = data
    }
    return str
  } else {
    return data
  }
}

export default {
  /**
   * 发送post请求
   * @param {string} url      请求URL
   * @param {string} type     请求类型 POST GET
   * @param {object} params   请求参数
   * @param {string} moreInfo   'open'表示在 app里
   * @return 请求Promise对象
   */
  async send (url, type, params, moreInfo) {
    // let headers = Object.assign(header, {})
    // if (true) {
    //   return
    // }
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
      // 'Access-Control-Allow-Credentials': true
    }
    // 在app 里
    if (moreInfo === 'open' && isOpenApp()) {
      try {
        let _userInfo = await NAPI.page.getUserInfo()

        if (_userInfo && typeof _userInfo === 'string') {
          _userInfo = JSON.parse(_userInfo)
        }
        headers['mob-token'] = _userInfo.mobToken || ''
      } catch (error) {
        // alert(error)
      }
    }
    function formatForSpecialRequest (axiosConfig) {
      if (axiosConfig.params) {
        /* 特殊接口场景1
        ** specialRequestType === 1 兼容下面格式请求
        ** axios.post(`${copenHost}/open/mob/basic/play/list.do`, ids, {
            headers: { 'Content-Type': 'application/json;charset=UTF-8' }
          })
        */
        if (axiosConfig.params.specialRequestType === 1) {
          axiosConfig.headers['Content-Type'] = 'application/json;charset=UTF-8'

          axiosConfig.data = axiosConfig.params.data || {}
          delete axiosConfig.params

        /* 特殊接口场景2
        ** specialRequestType === 2 兼容下面格式请求
        ** axios.post(`${copenHost}/open/mob/basic/play/list.do`, 'id=2484&pageIds%5B0%5D=2484', {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
          })
        */
        } else if (axiosConfig.params.specialRequestType === 2) {
          axiosConfig.data = qs.stringify(axiosConfig.params.data || {})
          delete axiosConfig.params
        }
      }
      return axiosConfig
    }
    // 网络检测
    // let netError = {
    //   message: '网络错误，请稍后重试'
    // }
    // let net = await NAPI.page.getNetworkInfo()
    // if(!net.networkType || net.networkType == 'unknown') {
    //   return Promise.reject(netError)
    // }

    let _sendParams = formatForSpecialRequest({
      url,
      method: type || 'GET',
      headers,
      params: params || '',
      withCredentials: true
    })

    return axios(_sendParams).then(({ data }) => {
      if (data.code === 200) {
        const temp = {
          ...data,
          data: addImgCdn(data.data)
        }
        return temp
      } else if (data.code === 20405) {
        const temp = {
          ...data,
          data: addImgCdn(data.data)
        }
        return temp
      } else {
        if (location.href.indexOf('vconsole') !== -1) {
          console.log(_sendParams, data)
        }
        return Promise.reject(data)
      }
    })
  }
}
