export default {
  back () {
    window.history.back()
  },
  /**
   *
   * @param {Function} cb 监听android物理返回键
   */
  linstenBack (cb) {

  },
  getUserInfo (param = {}) {
  },
  // 获取网络状态
  getNetworkInfo () {
    return new Promise((resolve) => {
      resolve({ networkType: 'html5' })
    })
  },
  setTitle (title) {
    document.title = title
  },
  goAppPage () {

  },
  picDetailArray (Picture) {
  },
  picTabAction (index) {
  },
  signIn ({ curl, from }) {
    if (!curl) {
      curl = encodeURIComponent(location.href)
    } else {
      curl = encodeURIComponent(curl)
    }
    // let url = `https://open.163.com/special/login_h5html/?curl=${curl}`
    let url = `${process.env.VUE_APP_MOBILE_ENV === 'production' ? '//open.163.com' : '//test.open.163.com'}/special/login_common?curl=${encodeURIComponent(location.href)}`
    if (from) {
      url = `${url}&from=${from}`
    }
    location.href = url
  },
  shareTo () {
    return new Promise((resolve) => {
      resolve()
    })
  },
  getPinCode () {
    return new Promise((resolve) => {
      resolve()
    })
  },
  interceptBack () {
  },
  setWebviewStyle () {
    return new Promise((resolve) => {
      resolve()
    })
  },
  tapSourceAction (url) {
    location.href = url
  },
  bindAccount () {
  },
  thirdLogin () {
  },
  showTips () {
  },
  showShareBtnFun () {
  }
}
