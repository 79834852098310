import PubSub from 'pubsub-js'
export default {
  back () {
    window.history.back()
  },
  /**
   *
   * @param {Function} cb 监听android物理返回键
   */
  linstenBack (cb) {
  },
  getUserInfo (param = {}) {
  },
  getDeviceInfo (param = {}) {
  },
  // 获取网络状态
  getNetworkInfo () {
    return new Promise((resolve) => {
      resolve({ networkType: 'wx' })
    })
  },
  setTitle (title) {
    document.title = title
  },
  goAppPage () {

  },
  picDetailArray (Picture) {
  },
  picTabAction (index) {
  },
  signIn ({ curl } = { curl: '' }) {
    if (!curl) {
      curl = location.href
    }
    const env = process.env.VUE_APP_MOBILE_ENV
    if (env === 'production') {
      location.href = 'https://c.open.163.com/open/mob/weixin/authorize.do?pageUri=' + encodeURIComponent(curl)
    } else {
      location.href = 'http://testc.open.163.com/open/mob/weixin/authorize.do?pageUri=' + encodeURIComponent(curl)
    }
  },
  shareTo (shareData) {
    return new Promise((resolve) => {
      let bindWeixinEvent = () => {
        // 发送给朋友
        WeixinJSBridge.on('menu:share:appmessage', function () {
          WeixinJSBridge.invoke('sendAppMessage', {
            img_url: shareData.imgUrl,
            link: shareData.link,
            desc: shareData.desc,
            title: shareData.title
          }, function (res) {
            if (res && res.err_msg && /confirm/.test(res.err_msg)) {
              PubSub.publish('share.weixin', { type: 'appmessage' })
            }
          })
          resolve({ type: 'weixinFriend' })
        })
        // 朋友圈
        WeixinJSBridge.on('menu:share:timeline', function (cbParam) {
          WeixinJSBridge.invoke('shareTimeline', {
            img_url: shareData.imgUrl,
            img_width: '200',
            img_height: '200',
            link: shareData.link,
            desc: shareData.desc,
            title: shareData.timeLineDesc || shareData.title
          }, function (res) {
            if (res && res.err_msg && /confirm/.test(res.err_msg)) {
              PubSub.publish('share.weixin', { type: 'timeline' })
            }
          })
          resolve({ type: 'weixinCircle' })
        })
      }
      if (typeof WeixinJSBridge === 'object') {
        bindWeixinEvent(shareData)
      } else {
        document.addEventListener('WeixinJSBridgeReady', function () {
          bindWeixinEvent(shareData)
        }, false)
      }
    })
  },
  getPinCode () {
  },
  setWebviewStyle () {
    return new Promise((resolve) => {
      resolve()
    })
  },
  interceptBack () {
  },
  tapSourceAction (url) {
    location.href = url
  },
  bindAccount () {
  },
  thirdLogin () {
  },
  showTips () {
  },
  showShareBtnFun (type, wxType) {
    if (isNaN(wxType)) {
      wxType = 1
    }
    if (wxType === 0) {
      document.addEventListener('WeixinJSBridgeReady', function () {
        window.WeixinJSBridge && WeixinJSBridge.call('hideOptionMenu')
      }, false)
    }
  }
}
