let JSONP = (config = {}) => {
  let { data, url, callback, name } = config
  if (!url) throw new Error('url is required!')
  let srcUrl = getSrcUrl(url, {
    data,
    callback: name
  })
  let script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = srcUrl
  script.id = name
  window[name] = function (json) {
    window[name] = undefined
    var elem = document.getElementById(name)
    removeElem(elem)
    callback && typeof callback === 'function' && callback(json)
  }
  var head = document.getElementsByTagName('head')
  if (head && head[0]) {
    head[0].appendChild(script)
  }
}
let getSrcUrl = (url, data) => {
  let _url = url + (url.indexOf('?') === -1 ? '?' : '&')
  let ret = ''
  if (typeof data === 'string') {
    ret = data
  } else if (typeof data === 'object') {
    for (let key in data) {
      ret += '&' + key + '=' + encodeURIComponent(data[key])
    }
  }
  ret = ret.substr(1)
  return _url + ret
}
let removeElem = (elem) => {
  let parent = elem.parentNode
  if (parent && parent.nodeType !== 11) {
    parent.removeChild(elem)
  }
}
export default JSONP
