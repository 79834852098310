// import NAPI from '@/adapter'
import service from '@/service'
import toast from '@/utils/toast'
import Jsonp from '@/utils/jsonp'

const MID = 'MID'
const PLID = 'PLID'

const CMS_CONFIG = 'CMS_CONFIG'
const CMS_CONFIG_LOADING = 'CMS_CONFIG_LOADING'

const MOVIE_LIST = 'MOVIE_LIST'
const MOVIE_LIST_LOADING = 'MOVIE_LIST_LOADING'
const MOVIE_PLAY_MID = 'MOVIE_PLAY_MID'

const MOVIE_RECOMMEND_LIST = 'MOVIE_RECOMMEND_LIST'
const MOVIE_RECOMMEND_LIST_LOADING = 'MOVIE_RECOMMEND_LIST_LOADING'

const AUDIO_LIST_INFO = 'AUDIO_LIST_INFO'
const AUDIO_LIST_INFO_LOADING = 'AUDIO_LIST_INFO_LOADING'
const AUDIO_PLAY_MID = 'AUDIO_PLAY_MID'

const FM_LOADING = 'FM_LOADING'
const FM_SINGLE = 'FM_SINGLE'
const FM_CHANNELS = 'FM_CHANNELS'
const FM_AUDIOS = 'FM_AUDIOS'

const SUBSCRIPTION_LOADING = 'SUBSCRIPTION_LOADING'
const SUBSCRIPTION_DETAIL = 'SUBSCRIPTION_DETAIL'
const SUBSCRIPTION_CONTENT_LIST_LOADING = 'SUBSCRIPTION_CONTENT_LIST_LOADING'
const SUBSCRIPTION_CONTENT_LIST = 'SUBSCRIPTION_CONTENT_LIST'
const SUBSCRIPTION_CONTENT_LIST_ADD = 'SUBSCRIPTION_CONTENT_LIST_ADD'

const SUBSCRIPTIONARTICLE_LOADING = 'SUBSCRIPTIONARTICLE_LOADING'
const SUBSCRIPTIONARTICLE_DETAIL = 'SUBSCRIPTIONARTICLE_DETAIL'
const SUBSCRIPTIONARTICLE_COMMENTS = 'SUBSCRIPTIONARTICLE_COMMENTS'
const SUBSCRIPTIONARTICLE_COMMENT_NUM = 'SUBSCRIPTIONARTICLE_COMMENT_NUM'

const VOTE_LOADING = 'VOTE_LOADING'
const VOTE_DETAIL = 'VOTE_DETAIL'
const VOTE_OPTIONS = 'VOTE_OPTIONS'
const VOTE_COMMENTS = 'VOTE_COMMENTS'
const VOTE_COMMENT_NUM = 'VOTE_COMMENT_NUM'

const RICHTEXTARTICLE_LOADING = 'RICHTEXTARTICLE_LOADING'
const RICHTEXTARTICLE_DETAIL = 'RICHTEXTARTICLE_DETAIL'
const RICHTEXTARTICLE_COMMENTS = 'RICHTEXTARTICLE_COMMENTS'
const RICHTEXTARTICLE_COMMENT_NUM = 'RICHTEXTARTICLE_COMMENT_NUM'

const QUESTION_LOADING = 'QUESTION_LOADING'
const QUESTION_DETAIL = 'QUESTION_DETAIL'
const QUESTION_COMMENTS = 'QUESTION_COMMENTS'
const QUESTION_COMMENT_NUM = 'QUESTION_COMMENT_NUM'

const IDEA_LOADING = 'IDEA_LOADING'
const IDEA_DETAIL = 'IDEA_DETAIL'
const IDEA_COMMENTS = 'IDEA_COMMENTS'
const IDEA_COMMENT_NUM = 'IDEA_COMMENT_NUM'

const CIRCLE_LOADING = 'CIRCLE_LOADING'
const CIRCLE_DETAIL = 'CIRCLE_DETAIL'
const CIRCLE_FEED = 'CIRCLE_FEED'
const CIRCLE_TOPIC_LIST = 'CIRCLE_TOPIC_LIST'
const CIRCLE_NOTICES = 'CIRCLE_NOTICES'

const CIRCLE_FEED_LOADING = 'CIRCLE_FEED_LOADING'
const CIRCLE_FEED_DETAIL = 'CIRCLE_FEED_DETAIL'
const CIRCLE_FEED_COMMENTS = 'CIRCLE_FEED_COMMENTS'
const CIRCLE_FEED_COMMENT_NUM = 'CIRCLE_FEED_COMMENT_NUM'

const COMMON_CIRCLE_LOADING = 'COMMON_CIRCLE_LOADING'
const COMMON_CIRCLE_FEED = 'COMMON_CIRCLE_FEED'

const TOPIC_LOADING = 'TOPIC_LOADING'
const TOPIC_DETAIL = 'TOPIC_DETAIL'
const TOPIC_FEED = 'TOPIC_FEED'

const state = {
  mid: null,
  plid: null,
  cmsConfig: null,
  cmsConfigLoading: null,
  movieList: null,
  movieListLoading: null,
  moviePlayMid: null,
  movieRecommendList: null,
  movieRecommendListLoading: null,
  audioListInfo: null,
  startIndex: null,
  totalIndex: null,
  endIndex: null,
  currentIndex: null,
  audioPlayMid: null,
  audioListInfoLoading: null,
  fmLoading: null,
  fmSingle: null,
  fmChannels: null,
  fmAudios: null,
  subscriptionLoading: null,
  subscriptionDetail: null,
  subscriptionContentListLoading: null,
  subscriptionContentList: null,
  subscriptionArticleLoading: null,
  subscriptionArticleDetail: null,
  subscriptionArticleComments: null,
  subscriptionArticleCommentNum: null,
  voteLoading: null,
  voteDetail: null,
  voteComments: null,
  voteCommentNum: null,
  richTextArticleLoading: null,
  richTextArticleDetail: null,
  richTextArticleComments: null,
  richTextArticleCommentNum: null,
  questionLoading: null,
  questionDetail: null,
  questionComments: null,
  questionCommentNum: null,
  ideaLoading: null,
  ideaDetail: null,
  ideaComments: null,
  ideaCommentNum: null,
  circleLoading: null,
  circleDetail: null,
  circleFeed: null,
  circleNotices: null,
  circleFeedLoading: null,
  circleFeedDetail: null,
  circleFeedComments: null,
  circleFeedCommentNum: null,
  commonCircleLoading: null,
  commonCircleFeed: null,
  topicLoading: null,
  topicDetail: null,
  topicFeed: null
}
const getters = {
  videoList ({ mid: _mid, movieList }) {
    let rightNull = true
    let cardList = []
    let layerList = []
    let layerStart = 0
    let layerEnd = 0
    const list = movieList && movieList.videoList ? movieList.videoList : []
    const videoCount = list.length
    let index = list.findIndex(({ mid }) => mid === _mid)
    index = index > 0 ? index : 0
    if (index + 6 < videoCount) {
      rightNull = false
      cardList = list.slice(index, index + 6)
    } else {
      cardList = list.slice(videoCount - 6 > 0 ? videoCount - 6 : 0)
    }
    layerStart = index - 30 > 0 ? index - 30 : 0
    layerEnd = index + 30 > videoCount - 1 ? videoCount : index + 30 + 1
    layerList = list.slice(layerStart, layerEnd)
    return { videoCount, cardList, rightNull, layerList, layerStart, layerEnd }
  },
  playVideo ({ moviePlayMid }, { videoList: { cardList } }) {
    return cardList.find(({ mid }) => mid === moviePlayMid)
  },
  playVideoIndex ({ moviePlayMid }, { videoList: { layerList } }) {
    return layerList.findIndex(({ mid }) => mid === moviePlayMid)
  },
  playVideoCardIndex ({ moviePlayMid }, { videoList: { cardList } }) {
    return cardList.findIndex(({ mid }) => mid === moviePlayMid)
  },
  audioList ({ audioListInfo, startIndex, totalIndex, endIndex, currentIndex }) {
    const rightNull = totalIndex > 0 && currentIndex + 6 > totalIndex
    const layerStart = startIndex > 0 ? startIndex - 1 : 0
    const layerEnd = endIndex > 0 ? endIndex - 1 : 0
    const audioCount = totalIndex > 0 ? totalIndex : 0
    const index = currentIndex > 0 ? currentIndex - startIndex : 0
    const layerList = audioListInfo && audioListInfo.audioList instanceof Array ? audioListInfo.audioList : []
    const layerLen = layerList.length
    const cardList = rightNull ? layerList.slice(layerLen - 6 > 0 ? layerLen - 6 : 0) : layerList.slice(index, index + 6)
    return { audioCount, cardList, rightNull, layerList, layerStart, layerEnd }
  },
  playAudio ({ audioPlayMid }, { audioList: { cardList } }) {
    return cardList.find(({ mid }) => mid === audioPlayMid)
  },
  playAudioIndex ({ audioPlayMid }, { audioList: { layerList } }) {
    return layerList.findIndex(({ mid }) => mid === audioPlayMid)
  },
  playAudioCardIndex ({ audioPlayMid }, { audioList: { cardList } }) {
    return cardList.findIndex(({ mid }) => mid === audioPlayMid)
  },
  subscription ({ subscriptionDetail, subscriptionContentList }) {
    const { data: contents = [], cursor } = subscriptionContentList || {}
    return { ...subscriptionDetail, cursor, contents }
  },
  subscriptionArticle ({ subscriptionArticleDetail, subscriptionArticleComments, subscriptionArticleCommentNum }) {
    subscriptionArticleComments = subscriptionArticleComments || []
    const { commonCommentNum: count = 0 } = subscriptionArticleCommentNum || {}
    return { ...subscriptionArticleDetail, subscriptionArticleComments, count }
  },
  vote ({ voteDetail, voteComments, voteCommentNum }) {
    voteComments = voteComments || []
    const { commonCommentNum: count = 0 } = voteCommentNum || {}
    return { ...voteDetail, voteComments, count }
  },
  richTextArticle ({ richTextArticleDetail, richTextArticleComments, richTextArticleCommentNum }) {
    richTextArticleComments = richTextArticleComments || []
    const { commonCommentNum: count = 0 } = richTextArticleCommentNum || {}
    return { ...richTextArticleDetail, richTextArticleComments, count }
  },
  article ({ articleDetail, articleComments, articleCommentNum }) {
    articleComments = articleComments || []
    const { commonCommentNum: count = 0 } = articleCommentNum || {}
    return { ...articleDetail, articleComments, count }
  },
  question ({ questionDetail, questionComments, questionCommentNum }) {
    questionComments = questionComments || []
    const { commonCommentNum: count = 0 } = questionCommentNum || {}
    return { ...questionDetail, questionComments, count }
  },
  idea ({ ideaDetail, ideaComments = [], ideaCommentNum }) {
    ideaComments = ideaComments || []
    const { commonCommentNum: count = 0 } = ideaCommentNum || {}
    return { ...ideaDetail, ideaComments, count }
  },
  circleFeed ({ circleFeedDetail, circleFeedComments = [], circleFeedCommentNum }) {
    circleFeedComments = circleFeedComments || []
    const { commonCommentNum: count = 0 } = circleFeedCommentNum || {}
    return { ...circleFeedDetail, circleFeedComments, count }
  }
}
const mutations = {
  [MID] (state, mid) {
    state.mid = mid
  },
  [PLID] (state, plid) {
    state.plid = plid
  },
  [CMS_CONFIG] (state, cmsConfig) {
    state.cmsConfig = cmsConfig
  },
  [CMS_CONFIG_LOADING] (state, cmsConfigLoading) {
    state.cmsConfigLoading = cmsConfigLoading
  },
  [MOVIE_LIST] (state, movieList) {
    state.movieList = movieList
    if (!state.mid && state.movieList.videoList && state.movieList.videoList.length > 0) {
      state.mid = state.movieList.videoList[0].mid
      state.moviePlayMid = state.movieList.videoList[0].mid
    }
  },
  [MOVIE_LIST_LOADING] (state, movieListLoading) {
    state.movieListLoading = movieListLoading
  },
  [MOVIE_PLAY_MID] (state, moviePlayMid) {
    state.moviePlayMid = moviePlayMid
  },
  [MOVIE_RECOMMEND_LIST] (state, movieRecommendList) {
    state.movieRecommendList = movieRecommendList
  },
  [MOVIE_RECOMMEND_LIST_LOADING] (state, movieRecommendListLoading) {
    state.movieRecommendListLoading = movieRecommendListLoading
  },
  [AUDIO_LIST_INFO] (state, data) {
    const { startIndex, totalIndex, endIndex, currentIndex, audioPlay: audioListInfo } = data || {}
    Object.assign(state, { startIndex, totalIndex, endIndex, currentIndex, audioListInfo })
  },
  [AUDIO_PLAY_MID] (state, audioPlayMid) {
    state.audioPlayMid = audioPlayMid
  },
  [AUDIO_LIST_INFO_LOADING] (state, audioListInfoLoading) {
    state.audioListInfoLoading = audioListInfoLoading
  },
  [FM_LOADING] (state, fmLoading) {
    state.fmLoading = fmLoading
  },
  [FM_SINGLE] (state, fmSingle) {
    state.fmSingle = fmSingle
  },
  [FM_CHANNELS] (state, fmChannels) {
    state.fmChannels = fmChannels
  },
  [FM_AUDIOS] (state, fmAudios) {
    state.fmAudios = fmAudios
  },
  [SUBSCRIPTION_LOADING] (state, subscriptionLoading) {
    state.subscriptionLoading = subscriptionLoading
  },
  [SUBSCRIPTION_DETAIL] (state, subscriptionDetail) {
    state.subscriptionDetail = subscriptionDetail
  },
  [SUBSCRIPTION_CONTENT_LIST_LOADING] (state, subscriptionContentListLoading) {
    state.subscriptionContentListLoading = subscriptionContentListLoading
  },
  [SUBSCRIPTION_CONTENT_LIST] (state, subscriptionContentList) {
    state.subscriptionContentList = subscriptionContentList
  },
  [SUBSCRIPTION_CONTENT_LIST_ADD] (state, { data, cursor } = { data: [] }) {
    const list = (state.subscriptionContentList && state.subscriptionContentList.data) || []
    Object.assign(state.subscriptionContentList, { cursor, data: [...list, ...data] })
  },
  [SUBSCRIPTIONARTICLE_LOADING] (state, subscriptionArticleLoading) {
    state.subscriptionArticleLoading = subscriptionArticleLoading
  },
  [SUBSCRIPTIONARTICLE_DETAIL] (state, subscriptionArticleDetail) {
    state.subscriptionArticleDetail = subscriptionArticleDetail
  },
  [SUBSCRIPTIONARTICLE_COMMENTS] (state, subscriptionArticleComments) {
    state.subscriptionArticleComments = subscriptionArticleComments
  },
  [SUBSCRIPTIONARTICLE_COMMENT_NUM] (state, subscriptionArticleCommentNum) {
    state.subscriptionArticleCommentNum = subscriptionArticleCommentNum
  },
  [VOTE_LOADING] (state, voteLoading) {
    state.voteLoading = voteLoading
  },
  [VOTE_DETAIL] (state, voteDetail) {
    state.voteDetail = voteDetail
  },
  [VOTE_OPTIONS] (state, optionList) {
    state.voteDetail.optionList = optionList
  },
  [VOTE_COMMENTS] (state, voteComments) {
    state.voteComments = voteComments
  },
  [VOTE_COMMENT_NUM] (state, voteCommentNum) {
    state.voteCommentNum = voteCommentNum
  },
  [RICHTEXTARTICLE_LOADING] (state, richTextArticleLoading) {
    state.richTextArticleLoading = richTextArticleLoading
  },
  [RICHTEXTARTICLE_DETAIL] (state, richTextArticleDetail) {
    state.richTextArticleDetail = richTextArticleDetail
  },
  [RICHTEXTARTICLE_COMMENTS] (state, richTextArticleComments) {
    state.richTextArticleComments = richTextArticleComments
  },
  [RICHTEXTARTICLE_COMMENT_NUM] (state, richTextArticleCommentNum) {
    state.richTextArticleCommentNum = richTextArticleCommentNum
  },
  [QUESTION_LOADING] (state, questionLoading) {
    state.questionLoading = questionLoading
  },
  [QUESTION_DETAIL] (state, questionDetail) {
    state.questionDetail = questionDetail
  },
  [QUESTION_COMMENTS] (state, questionComments) {
    state.questionComments = questionComments
  },
  [QUESTION_COMMENT_NUM] (state, questionCommentNum) {
    state.questionCommentNum = questionCommentNum
  },
  [IDEA_LOADING] (state, ideaLoading) {
    state.ideaLoading = ideaLoading
  },
  [IDEA_DETAIL] (state, ideaDetail) {
    state.ideaDetail = ideaDetail
  },
  [IDEA_COMMENTS] (state, ideaComments) {
    state.ideaComments = ideaComments
  },
  [IDEA_COMMENT_NUM] (state, ideaCommentNum) {
    state.ideaCommentNum = ideaCommentNum
  },
  [CIRCLE_LOADING] (state, circleLoading) {
    state.circleLoading = circleLoading
  },
  [CIRCLE_DETAIL] (state, circleDetail) {
    state.circleDetail = circleDetail
  },
  [CIRCLE_FEED] (state, circleFeed) {
    state.circleFeed = circleFeed
  },
  [CIRCLE_NOTICES] (state, circleNotices) {
    state.circleNotices = circleNotices
  },
  [CIRCLE_TOPIC_LIST] (state, circleTopicList) {
    state.circleTopicList = circleTopicList
  },
  [CIRCLE_FEED_LOADING] (state, circleFeedLoading) {
    state.circleFeedLoading = circleFeedLoading
  },
  [CIRCLE_FEED_DETAIL] (state, circleFeedDetail) {
    state.circleFeedDetail = circleFeedDetail
  },
  [CIRCLE_FEED_COMMENTS] (state, circleFeedComments) {
    state.circleFeedComments = circleFeedComments
  },
  [CIRCLE_FEED_COMMENT_NUM] (state, circleFeedCommentNum) {
    state.circleFeedCommentNum = circleFeedCommentNum
  },
  [COMMON_CIRCLE_LOADING] (state, commonCircleLoading) {
    state.commonCircleLoading = commonCircleLoading
  },
  [COMMON_CIRCLE_FEED] (state, commonCircleFeed) {
    state.commonCircleFeed = commonCircleFeed
  },
  [TOPIC_LOADING] (state, topicLoading) {
    state.topicLoading = topicLoading
  },
  [TOPIC_DETAIL] (state, topicDetail) {
    state.topicDetail = topicDetail
  },
  [TOPIC_FEED] (state, topicFeed) {
    state.topicFeed = topicFeed
  }
}

const actions = {
  async getCmsConfig ({ commit }) {
    commit(CMS_CONFIG_LOADING, true)
    let cmsConfig = await new Promise((resolve, reject) => {
      Jsonp({
        url: 'https://open.163.com/special/00859AET/guide_back_config',
        name: 'guide_back_config_callback',
        callback (data) {
          resolve(data.data)
        }
      })
    })
    commit(CMS_CONFIG, cmsConfig)
    commit(CMS_CONFIG_LOADING, false)
  },
  async fetchMovieList ({ commit }, params) {
    commit(MOVIE_LIST_LOADING, true)
    try {
      let res = await service.getMovieList(params)
      commit(MOVIE_LIST, res.data)
    } catch (e) {
      toast.show(e.message)
    } finally {
      commit(MOVIE_LIST_LOADING, false)
    }
  },
  changePlayMid ({ commit }, mid) {
    commit(AUDIO_PLAY_MID, mid)
  },
  async fetchAudioListInfo ({ commit, state: { plid: curPlid, audioListInfo, audioListInfoLoading } }, { plid, mid }) {
    try {
      if (plid && mid) {
        if (curPlid && plid === curPlid && audioListInfo) {
          commit(AUDIO_PLAY_MID, mid)
          commit(MID, mid)
          return
        }
      }
      commit(PLID, plid)
      if (mid) {
        commit(AUDIO_PLAY_MID, mid)
        commit(MID, mid)
      }
      if (!plid || audioListInfoLoading) return
      if (audioListInfo && plid !== audioListInfo.plid) {
        commit(AUDIO_LIST_INFO, null)
      }
      commit(AUDIO_LIST_INFO_LOADING, true)
      let res = await service.getAudioListForBackGuide({ plid, mid })
      const { audioPlay } = res.data || {}
      commit(AUDIO_LIST_INFO, res.data)
      if (!mid) {
        const firstMid = audioPlay && audioPlay.audioList && audioPlay.audioList[0] && audioPlay.audioList[0].mid
        commit(AUDIO_PLAY_MID, firstMid)
        commit(MID, firstMid)
      }
    } catch (e) {
      toast.show(e.message)
    } finally {
      commit(AUDIO_LIST_INFO_LOADING, false)
    }
  },
  async fetchFm ({ commit, state: { fmLoading, fmAudios, fmChannels } }, { plid, mid }) {
    // fetch single
    if (fmLoading) return
    commit(FM_LOADING, true)
    try {
      commit(FM_SINGLE, null)
      if (plid && mid) {
        await service.getAudioListForBackGuide({ plid, mid, aroundLimit: 0 }).then(res => {
          const { audioPlay: { audioList: [ singleAudio ] } } = res.data
          commit(FM_SINGLE, singleAudio)
        })
      }
    } catch (e) { }
    try {
      let channels = Array.isArray(fmChannels) && fmChannels.length ? fmChannels : null
      let audios = fmAudios
      if (!channels) {
        // fetch channels
        const newChannels = await service.getFmChannelsForBackGuide({}).then(res => res.data).then(chnns => Array.isArray(chnns) && chnns.length ? chnns : null)
        if (newChannels) {
          commit(FM_CHANNELS, newChannels)
          channels = newChannels
        }
      }
      if (channels && !audios) {
        // fetch audios
        const fetchArray = []
        for (let i in channels) {
          const { moduleId: tabId } = channels[i]
          fetchArray.push(service.getFmAudiosForBackGuide({ tabId, pagesize: 6 }).then(res => res.data))
        }
        const newAudios = await Promise.all(fetchArray)
        const newList = []
        for (let i in newAudios) {
          const curlist = newAudios[i]
          newList.push({
            ...channels[i],
            list: curlist
          })
        }
        commit(FM_AUDIOS, newList)
      }
    } catch (e) {
      toast.show(e.message)
    } finally {
      commit(FM_LOADING, false)
    }
  },
  async fetchMovieRecommendList ({ commit }, params) {
    commit(MOVIE_RECOMMEND_LIST_LOADING, true)
    try {
      let res = await service.getMovieRecommendList(params)
      commit(MOVIE_RECOMMEND_LIST, res.data)
    } catch (e) {
      toast.show(e.message)
    } finally {
      commit(MOVIE_RECOMMEND_LIST_LOADING, false)
    }
  },
  async fetchSubscriptionDetail ({ commit, state: { subscriptionLoading } }, { subscribeId }) {
    try {
      const rtypes = '2,3,4,5,6,8,9,10,11,12'
      const pagesize = 10
      const cursor = ''
      if (!subscribeId) {
        commit(SUBSCRIPTION_DETAIL, null)
        commit(SUBSCRIPTION_CONTENT_LIST, null)
        return
      }
      if (subscriptionLoading) return
      commit(SUBSCRIPTION_LOADING, true)
      const res = await Promise.all([
        service.getSubscriptionDetail({ subscribeId }),
        service.getSubscriptionList({ subscribeId, rtypes, cursor, pagesize })
      ])
      commit(SUBSCRIPTION_LOADING, false)
      commit(SUBSCRIPTION_DETAIL, res[0].data)
      commit(SUBSCRIPTION_CONTENT_LIST, res[1])
    } catch (e) {
      commit(SUBSCRIPTION_LOADING, false)
      commit(SUBSCRIPTION_DETAIL, null)
      commit(SUBSCRIPTION_CONTENT_LIST, null)
      toast.show(e.message)
    }
  },
  async fetchSubscriptionContentList ({
    commit,
    state: {
      subscriptionDetail: { subscribeId },
      subscriptionContentList: { cursor },
      subscriptionContentListLoading
    }
  }) {
    try {
      const rtypes = '2,3,4,5,6,8,9,10,11,12'
      const pagesize = 10
      if (!subscribeId || !cursor || subscriptionContentListLoading) return
      commit(SUBSCRIPTION_CONTENT_LIST_LOADING, true)
      const res = await service.getSubscriptionList({ subscribeId, rtypes, cursor, pagesize })
      commit(SUBSCRIPTION_CONTENT_LIST_LOADING, false)
      commit(SUBSCRIPTION_CONTENT_LIST_ADD, res)
    } catch (e) {
      commit(SUBSCRIPTION_CONTENT_LIST_LOADING, false)
      toast.show(e.message)
    }
  },
  async fetchSubscriptionArticle ({ commit, state: { subscriptionArticleLoading } }, { id }) {
    try {
      let type = 4
      const sort = 1
      const pagesize = 3
      if (!id) {
        commit(SUBSCRIPTIONARTICLE_DETAIL, null)
        commit(SUBSCRIPTIONARTICLE_COMMENTS, null)
        commit(SUBSCRIPTIONARTICLE_COMMENT_NUM, null)
        return
      }
      if (subscriptionArticleLoading) return
      commit(SUBSCRIPTIONARTICLE_LOADING, true)
      const res = await service.getSubscriptionArticle({ id })
      const { id: contentId, type: contentType } = res.data || {}
      type = contentType === 1 ? 4 : 5
      const res1 = await Promise.all([
        service.getCommentListNew({ contentId, type, sort, pagesize }),
        service.getCommentNum({ contentId, type })
      ])
      commit(SUBSCRIPTIONARTICLE_LOADING, false)
      commit(SUBSCRIPTIONARTICLE_DETAIL, res.data)
      commit(SUBSCRIPTIONARTICLE_COMMENTS, res1[0].data)
      commit(SUBSCRIPTIONARTICLE_COMMENT_NUM, res1[1].data)
    } catch (e) {
      commit(SUBSCRIPTIONARTICLE_LOADING, false)
      commit(SUBSCRIPTIONARTICLE_DETAIL, null)
      commit(SUBSCRIPTIONARTICLE_COMMENTS, null)
      commit(SUBSCRIPTIONARTICLE_COMMENT_NUM, null)
      toast.show(e.message)
    }
  },
  async fetchSubscriptionArticleComment ({ commit }, { contentId, contentType }) {
    try {
      let type = contentType === 1 ? 4 : 5
      const sort = 1
      const pagesize = 3
      const res1 = await Promise.all([
        service.getCommentListNew({ contentId, type, sort, pagesize }),
        service.getCommentNum({ contentId, type })
      ])
      commit(SUBSCRIPTIONARTICLE_COMMENTS, res1[0].data)
      commit(SUBSCRIPTIONARTICLE_COMMENT_NUM, res1[1].data)
    } catch (e) {
      commit(SUBSCRIPTIONARTICLE_COMMENTS, null)
      commit(SUBSCRIPTIONARTICLE_COMMENT_NUM, null)
      toast.show(e.message)
    }
  },
  async fetchVoteDetail ({ commit, state: { voteLoading } }, { id }) {
    try {
      const type = 10
      const sort = 1
      const pagesize = 3
      const contentId = id
      if (!id) {
        commit(VOTE_DETAIL, null)
        commit(VOTE_COMMENTS, null)
        commit(VOTE_COMMENT_NUM, null)
        return
      }
      if (voteLoading) return
      commit(VOTE_LOADING, true)
      const res = await Promise.all([
        service.getVoteDetail({ id }),
        service.getCommentListNew({ contentId, type, sort, pagesize }),
        service.getCommentNum({ contentId, type })
      ])
      commit(VOTE_LOADING, false)
      commit(VOTE_DETAIL, res[0].data)
      commit(VOTE_COMMENTS, res[1].data)
      commit(VOTE_COMMENT_NUM, res[2].data)
    } catch (e) {
      commit(VOTE_LOADING, false)
      commit(VOTE_DETAIL, null)
      commit(VOTE_COMMENTS, null)
      commit(VOTE_COMMENT_NUM, null)
      toast.show(e.message)
    }
  },
  async fetchVoteResult ({ commit }, { id }) {
    try {
      if (!id) return
      const res = await service.getVoteDetail({ id })
      if (res && res.data && res.data.optionList) commit(VOTE_OPTIONS, res.data.optionList)
    } catch (e) {}
  },
  async fetchRichTextArticle ({ commit, state: { richTextArticleLoading } }, { id, inApp }) {
    try {
      let type
      const sort = 1
      const pagesize = 3
      const contentId = id
      if (!id) {
        commit(RICHTEXTARTICLE_DETAIL, null)
        commit(RICHTEXTARTICLE_COMMENTS, null)
        commit(RICHTEXTARTICLE_COMMENT_NUM, null)
        return
      }
      if (richTextArticleLoading) return
      commit(RICHTEXTARTICLE_LOADING, true)
      const res = await service.getRichTextArticle({ id })
      const { type: contentType } = res.data
      type = { 58: 8, 59: 9 }[contentType]
      commit(RICHTEXTARTICLE_LOADING, false)
      commit(RICHTEXTARTICLE_DETAIL, res.data)
      if (contentId && !inApp) {
        const res1 = await Promise.all([
          service.getCommentListNew({ contentId, type, sort, pagesize }),
          service.getCommentNum({ contentId, type })
        ])
        commit(RICHTEXTARTICLE_COMMENTS, res1[0].data)
        commit(RICHTEXTARTICLE_COMMENT_NUM, res1[1].data)
      }
    } catch (e) {
      commit(RICHTEXTARTICLE_LOADING, false)
      commit(RICHTEXTARTICLE_DETAIL, null)
      commit(RICHTEXTARTICLE_COMMENTS, null)
      commit(RICHTEXTARTICLE_COMMENT_NUM, null)
      toast.show(e.message)
    }
  },
  async fetchQuestionDetail ({ commit, state: { questionLoading } }, { id }) {
    try {
      const type = 20
      const sort = 1
      const pagesize = 3
      const contentId = id
      if (!id) {
        commit(QUESTION_DETAIL, null)
        commit(QUESTION_COMMENTS, null)
        commit(QUESTION_COMMENT_NUM, null)
        return
      }
      if (questionLoading) return
      commit(QUESTION_LOADING, true)
      const res = await Promise.all([
        service.getQuestionDetail({ id }),
        service.getCommentListNew({ contentId, type, sort, pagesize }),
        service.getCommentNum({ contentId, type })
      ])
      commit(QUESTION_LOADING, false)
      commit(QUESTION_DETAIL, res[0].data)
      commit(QUESTION_COMMENTS, res[1].data)
      commit(QUESTION_COMMENT_NUM, res[2].data)
    } catch (e) {
      commit(QUESTION_LOADING, false)
      commit(QUESTION_DETAIL, null)
      commit(QUESTION_COMMENTS, null)
      commit(QUESTION_COMMENT_NUM, null)
      toast.show(e.message)
    }
  },
  async fetchIdeaDetail ({ commit, state: { ideaLoading } }, { id }) {
    try {
      commit(IDEA_LOADING, true)
      const type = 211
      const sort = 1
      const pagesize = 3
      const contentId = id
      if (!id) {
        commit(IDEA_DETAIL, null)
        commit(IDEA_COMMENTS, null)
        commit(IDEA_COMMENT_NUM, null)
        return
      }
      if (ideaLoading) return
      const res = await Promise.all([
        service.getIdeaDetail({ id }),
        service.getCommentListNew({ contentId, type, sort, pagesize }),
        service.getCommentNum({ contentId, type })
      ])
      commit(IDEA_LOADING, false)
      commit(IDEA_DETAIL, res[0].data)
      commit(IDEA_COMMENTS, res[1].data)
      commit(IDEA_COMMENT_NUM, res[2].data)
    } catch (e) {
      commit(IDEA_LOADING, false)
      commit(IDEA_DETAIL, null)
      commit(IDEA_COMMENTS, null)
      commit(IDEA_COMMENT_NUM, null)
      toast.show(e.message)
    }
  },
  async fetchTopicDetail ({ commit, state: { topicLoading, topicDetail } }, { id: topicId, order, type = 'default' /*default or update */ }) {
    try {
      commit(TOPIC_LOADING, true && type === 'default')
      if (!topicId) {
        commit(TOPIC_DETAIL, null)
        commit(TOPIC_FEED, null)
        commit(TOPIC_LOADING, false)
        return
      }
      if (topicLoading) return
      const resAll = await Promise.all([
        service.getTopicDetail({ topicId }),
        service.getTopicFeed({ topicId, order, cursor: '' }),
      ])
      commit(TOPIC_LOADING, false)
      commit(TOPIC_DETAIL, resAll[0].data)
      const res = resAll[1]
      if (res.data && Array.isArray(res.data)) {
        for (let i = 0; i < res.data.length; i++) {
          const cur = res.data[i]
          if (typeof cur.description === 'string') {
            cur.description = cur.description.replace(/(^[\s\n\r\t]+|[\s\n\r\t]+$)/g, '').replace(/\n[\s\n\r\t]+\n/g, '\n\n')
            let showtext = cur.description.substr(0, 40)
            let showtextArray = showtext.split('\n')
            if (showtextArray.length === 1) cur.showcontent = showtext
            else if (showtextArray[0].length > 24) cur.showcontent = showtextArray[0]
            else if (showtextArray[1].length) cur.showcontent = `${showtextArray[0]}\n${showtextArray[1].substr(0, 20)}`
            else cur.showcontent = `${showtextArray[0]}\n\n${showtextArray[2].substr(0, 20)}`
            cur.showbtn = cur.showcontent !== cur.description
          }
        }
      }
      commit(TOPIC_FEED, res.data)
    } catch (e) {
      commit(TOPIC_LOADING, false)
      commit(TOPIC_DETAIL, null)
      commit(TOPIC_FEED, null)
      toast.show(e.message)
    }
  },
  async fetchCircleDetail ({ commit, state: { circleLoading } }, { id: groupId }) {
    try {
      commit(CIRCLE_LOADING, true)
      if (!groupId) {
        commit(CIRCLE_DETAIL, null)
        commit(CIRCLE_FEED, null)
        commit(CIRCLE_NOTICES, null)
        commit(CIRCLE_TOPIC_LIST, null)
        commit(CIRCLE_LOADING, false)
        return
      }
      if (circleLoading) return
      const resAll = await Promise.all([
        service.getCircleDetail({ groupId }),
        service.getCircleFeed({ groupId, pagesize: 10 }),
        service.getCircleNotices({ groupId }),
        service.getTopicList({ groupId, scene: 1, cursor: '' }),
      ])
      commit(CIRCLE_LOADING, false)
      commit(CIRCLE_DETAIL, resAll[0].data)
      const res = resAll[1]
      if (res.data && Array.isArray(res.data)) {
        for (let i = 0; i < res.data.length; i++) {
          const cur = res.data[i]
          if (typeof cur.description === 'string') {
            cur.description = cur.description.replace(/(^[\s\n\r\t]+|[\s\n\r\t]+$)/g, '').replace(/\n[\s\n\r\t]+\n/g, '\n\n')
            let showtext = cur.description.substr(0, 40)
            let showtextArray = showtext.split('\n')
            if (showtextArray.length === 1) cur.showcontent = showtext
            else if (showtextArray[0].length > 24) cur.showcontent = showtextArray[0]
            else if (showtextArray[1].length) cur.showcontent = `${showtextArray[0]}\n${showtextArray[1].substr(0, 20)}`
            else cur.showcontent = `${showtextArray[0]}\n\n${showtextArray[2].substr(0, 20)}`
            cur.showbtn = cur.showcontent !== cur.description
          }
        }
      }
      commit(CIRCLE_FEED, res.data)
      commit(CIRCLE_NOTICES, resAll[2].data)
      commit(CIRCLE_TOPIC_LIST, resAll[3].data)
    } catch (e) {
      commit(CIRCLE_LOADING, false)
      commit(CIRCLE_DETAIL, null)
      commit(CIRCLE_FEED, null)
      commit(CIRCLE_NOTICES, null)
      commit(CIRCLE_TOPIC_LIST, null)
      toast.show(e.message)
    }
  },
  async fetchCircleFeedDetail ({ commit, state: { circleFeedLoading } }, { id: classBreakId }) {
    try {
      commit(CIRCLE_FEED_LOADING, true)
      const sort = 1
      const pagesize = 3
      const contentId = classBreakId
      if (!classBreakId) {
        commit(CIRCLE_FEED_DETAIL, null)
        commit(CIRCLE_FEED_COMMENTS, null)
        commit(CIRCLE_FEED_COMMENT_NUM, null)
        return
      }
      if (circleFeedLoading) return
      const res = await service.getCircleFeedDetail({ classBreakId })
      const type = (res && res.data && res.data.contentType) || 211
      const res1 = await Promise.all([
        service.getCommentListNew({ contentId, type, sort, pagesize }),
        service.getCommentNum({ contentId, type })
      ])
      commit(CIRCLE_FEED_LOADING, false)
      commit(CIRCLE_FEED_DETAIL, res.data)
      commit(CIRCLE_FEED_COMMENTS, res1[0].data)
      commit(CIRCLE_FEED_COMMENT_NUM, res1[1].data)
    } catch (e) {
      commit(CIRCLE_FEED_LOADING, false)
      commit(CIRCLE_FEED_DETAIL, null)
      commit(CIRCLE_FEED_COMMENTS, null)
      commit(CIRCLE_FEED_COMMENT_NUM, null)
      toast.show(e.message)
    }
  },
  async fetchCommonCircle ({ commit, state: { commonCircleLoading } }) {
    try {
      if (commonCircleLoading) return
      commit(COMMON_CIRCLE_LOADING, true)
      const res = await service.getCommonCircleFeed({})
      commit(COMMON_CIRCLE_LOADING, false)
      if (res.data && Array.isArray(res.data)) {
        for (let i = 0; i < res.data.length; i++) {
          const cur = res.data[i]
          if (typeof cur.description === 'string') {
            cur.description = cur.description.replace(/(^[\s\n\r\t]+|[\s\n\r\t]+$)/g, '').replace(/\n[\s\n\r\t]+\n/g, '\n\n')
            let showtext = cur.description.substr(0, 40)
            let showtextArray = showtext.split('\n')
            if (showtextArray.length === 1) cur.showcontent = showtext
            else if (showtextArray[0].length > 24) cur.showcontent = showtextArray[0]
            else if (showtextArray[1].length) cur.showcontent = `${showtextArray[0]}\n${showtextArray[1].substr(0, 20)}`
            else cur.showcontent = `${showtextArray[0]}\n\n${showtextArray[2].substr(0, 20)}`
            cur.showbtn = cur.showcontent !== cur.description
          }
        }
      }
      commit(COMMON_CIRCLE_FEED, res.data)
    } catch (e) {
      commit(COMMON_CIRCLE_LOADING, false)
      commit(COMMON_CIRCLE_FEED, null)
      toast.show(e.message)
    }
  },
  cleanAudio ({ commit }) {
    commit(AUDIO_LIST_INFO_LOADING, null)
    commit(AUDIO_LIST_INFO, null)
  },
  cleanFm ({ commit }) {
    commit(FM_LOADING, null)
    commit(FM_SINGLE, null)
  },
  cleanSubscription ({ commit }) {
    commit(SUBSCRIPTION_LOADING, null)
    commit(SUBSCRIPTION_DETAIL, null)
    commit(SUBSCRIPTION_CONTENT_LIST, null)
    commit(SUBSCRIPTION_CONTENT_LIST_LOADING, null)
  },
  cleanSubscriptionArticle ({ commit }) {
    commit(SUBSCRIPTIONARTICLE_LOADING, null)
    commit(SUBSCRIPTIONARTICLE_DETAIL, null)
    commit(SUBSCRIPTIONARTICLE_COMMENTS, null)
    commit(SUBSCRIPTIONARTICLE_COMMENT_NUM, null)
  },
  cleanVote ({ commit }) {
    commit(VOTE_LOADING, null)
    commit(VOTE_DETAIL, null)
    commit(VOTE_COMMENTS, null)
    commit(VOTE_COMMENT_NUM, null)
  },
  cleanRichTextArticle ({ commit }) {
    commit(RICHTEXTARTICLE_LOADING, null)
    commit(RICHTEXTARTICLE_DETAIL, null)
    commit(RICHTEXTARTICLE_COMMENTS, null)
    commit(RICHTEXTARTICLE_COMMENT_NUM, null)
  },
  cleanQuestion ({ commit }) {
    commit(QUESTION_LOADING, null)
    commit(QUESTION_DETAIL, null)
    commit(QUESTION_COMMENTS, null)
    commit(QUESTION_COMMENT_NUM, null)
  },
  cleanIdea ({ commit }) {
    commit(IDEA_LOADING, null)
    commit(IDEA_DETAIL, null)
    commit(IDEA_COMMENTS, null)
    commit(IDEA_COMMENT_NUM, null)
  },
  cleanCircle ({ commit }) {
    commit(CIRCLE_LOADING, null)
    commit(CIRCLE_DETAIL, null)
    commit(CIRCLE_FEED, null)
    commit(CIRCLE_NOTICES, null)
    commit(CIRCLE_TOPIC_LIST, null)
  },
  cleanCircleFeed ({ commit }) {
    commit(CIRCLE_FEED_LOADING, null)
    commit(CIRCLE_FEED_DETAIL, null)
    commit(CIRCLE_FEED_COMMENTS, null)
    commit(CIRCLE_FEED_COMMENT_NUM, null)
  },
  cleanCommonCircle ({ commit }) {
    commit(COMMON_CIRCLE_LOADING, null)
    commit(COMMON_CIRCLE_FEED, null)
  },
  cleanTopic ({ commit }) {
    commit(TOPIC_LOADING, null)
    commit(TOPIC_DETAIL, null)
    commit(TOPIC_FEED, null)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
